export class HeremapsAddress {
	label: string;
	countryCode: string;
	countryName: string;
	state: string;
	county: string;
	city: string;
	district: string;
	street: string;
	postalCode: string;
	houseNumber?: string;
}
