import { FC, PropsWithChildren } from 'react';
import { of } from 'rxjs';
import { useService } from '@hooks';
import { CUSTOM_THEME } from '@utils';
import { ICalculationInputs } from '@models';
import { b2cStockService } from '@services';
import { TextField, Grid, Autocomplete, Paper } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { CardVehicleSummary } from '../CardVehicleSummary';
import { useTranslation } from 'react-i18next';

const AutocompleteSx: SxProps = {
	border: 'none',
	'& .MuiOutlinedInput-root': {
		border: 'none',
		padding: '0'
	},
	'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
		border: 'none'
	}
};

export interface ISelectorVehicleProps {
	brand?: string;
	model?: string;
	version?: string;
	onValueChange?: (val?: Partial<ICalculationInputs>) => void;
	onReset?: () => void;
}

export const SelectorVehicle: FC<PropsWithChildren<ISelectorVehicleProps>> = ({ brand, model, version, onValueChange, onReset }) => {
	const isValid = brand && model && version;
	const { t } = useTranslation('proposal');
	const { data: brands = [] } = useService(() => b2cStockService.getAllBrands(), []);
	const { data: models = [] } = useService(() => (brand ? b2cStockService.getAllModels(brand) : of([])), [brand]);
	const { data: versions = [] } = useService(() => (brand && model ? b2cStockService.getAllVersions(brand, model) : of([])), [model]);

	if (isValid) {
		return <CardVehicleSummary brand={brand} model={model} version={version} onReset={onReset} />;
	}

	return (
		<Grid container spacing={{ xs: 2.5, [CUSTOM_THEME.mobileUntil]: 1 }}>
			<Grid item xs={12}>
				<Autocomplete
					// isOptionEqualToValue={(option, value) => option.carModel?.brand === value.carModel?.brand}
					// getOptionLabel={(option) => (typeof option === 'string' ? option : option.carModel?.brand)}
					options={Array.isArray(brands) ? brands.sort() : []}
					fullWidth
					value={brand || ''}
					sx={AutocompleteSx}
					forcePopupIcon={false}
					PaperComponent={({ children }) => <Paper style={{ fontSize: '11px' }}>{children}</Paper>}
					onChange={(e, value) => {
						onValueChange?.({ brand: value || '', model: '', version: '' });
					}}
					onInputChange={(e, value, reason) => {
						reason === 'clear' && onValueChange?.({ brand: '', model: '', version: '' });
					}}
					renderInput={(params) => (
						<TextField
							variant="outlined"
							{...params}
							name="brand"
							placeholder={t('calculation-inputs.selector-vehicle.brand-placeholder')}
							required
							hiddenLabel
							color="secondary"
							InputProps={{
								...params.InputProps,
								endAdornment: params.InputProps.endAdornment
							}}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autocomplete
					// isOptionEqualToValue={(option, value) => option.carModel?.model === value.carModel?.model}
					// getOptionLabel={(option) => (typeof option === 'string' ? option : option.carModel?.model)}
					options={Array.isArray(models) ? models.sort() : []}
					fullWidth
					value={model || ''}
					sx={AutocompleteSx}
					forcePopupIcon={false}
					PaperComponent={({ children }) => <Paper style={{ fontSize: '11px' }}>{children}</Paper>}
					onChange={(e, value) => {
						onValueChange?.({ model: value || '', version: '' });
					}}
					onInputChange={(e, value, reason) => {
						reason === 'clear' && onValueChange?.({ model: '', version: '' });
					}}
					renderInput={(params) => (
						<TextField
							variant="outlined"
							{...params}
							name="model"
							placeholder={t('calculation-inputs.selector-vehicle.model-placeholder')}
							required
							hiddenLabel
							color="secondary"
							InputProps={{
								...params.InputProps,
								endAdornment: params.InputProps.endAdornment
							}}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autocomplete
					// isOptionEqualToValue={(option, value) => option.carModel?.version === value.carModel?.version}
					// getOptionLabel={(option) => (typeof option === 'string' ? option : option.carModel?.version)}
					options={Array.isArray(versions) ? versions.sort() : []}
					fullWidth
					value={version || ''}
					sx={AutocompleteSx}
					forcePopupIcon={false}
					PaperComponent={({ children }) => <Paper style={{ fontSize: '11px' }}>{children}</Paper>}
					onChange={(e, value) => onValueChange?.({ version: value || '' })}
					onInputChange={(e, value, reason) => {
						reason === 'clear' && onValueChange?.({ version: '' });
					}}
					renderInput={(params) => (
						<TextField
							variant="outlined"
							{...params}
							name="version"
							placeholder={t('calculation-inputs.selector-vehicle.version-placeholder')}
							required
							hiddenLabel
							color="secondary"
							InputProps={{
								...params.InputProps,
								endAdornment: params.InputProps.endAdornment
							}}
						/>
					)}
				/>
			</Grid>
		</Grid>
	);
};
