import { FC, PropsWithChildren, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { useMobile } from '@hooks';
import { CUSTOM_THEME, NumberUtils, Tr } from '@utils';
import { Stack, Typography } from '@mui/material';
import { IVoltaageCardProps, VoltaageCard } from '@components/common';
import { TreeFullIcon } from '@components/icons';
import { ModalCO2Reduction } from '../ModalCO2Reduction';

export interface ICardCO2ReductionProps extends IVoltaageCardProps {
	treesSavedPerYear?: number;
	co2Total?: number;
}

export const CardCO2Reduction: FC<PropsWithChildren<ICardCO2ReductionProps>> = ({ treesSavedPerYear, co2Total, sx, ...cardProps }) => {
	const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
	const isMobile = useMobile();

	return (
		<>
			<VoltaageCard
				sx={{ backgroundColor: '#88AF2B', paddingY: 2.5, borderRadius: 4, ...sx }}
				elevation={0}
				info
				onInfoIconClick={() => {
					ReactGA4.event({ category: 'Trees', action: 'Info Clicked' });
					setIsInfoModalOpen(true);
				}}
				{...cardProps}
			>
				<Stack direction="column" spacing={1.25} sx={{ paddingX: 2.5 }}>
					<Stack
						alignItems="baseline"
						sx={{ gap: 0.5, flexDirection: { xs: 'column', sm: 'row', [CUSTOM_THEME.mobileUntil]: 'row' } }}
					>
						{!isMobile && <TreeFullIcon sx={{ marginRight: 1 }} />}
						<Typography variant="h6" sx={{ fontWeight: 600, lineHeight: '24px' }}>
							{NumberUtils.formatAsNumber(treesSavedPerYear, { decimalScale: 0 })}
						</Typography>
						<Typography variant="caption" sx={{ lineHeight: '14px' }}>
							<Tr.Proposal path="result.co2reduction.trees-yearly" />
						</Typography>
					</Stack>

					<Typography variant="caption">
						<Tr.Proposal
							path="result.co2reduction.description"
							options={{ co2: NumberUtils.formatAsNumber(co2Total, { decimalScale: 2 }) }}
							withInsecureTemplating
						/>
					</Typography>
				</Stack>
			</VoltaageCard>
			<ModalCO2Reduction
				treesSavedPerYear={treesSavedPerYear}
				co2Total={co2Total}
				open={isInfoModalOpen}
				onClose={() => setIsInfoModalOpen(false)}
			/>
		</>
	);
};
