import { FC, PropsWithChildren } from 'react';
import { CardProps, Card, IconButton } from '@mui/material';
import { InfoIcon } from '@components/icons';

export interface IVoltaageCardProps extends CardProps {
	info?: boolean;
	onInfoIconClick?: () => void;
}

export const VoltaageCard: FC<PropsWithChildren<IVoltaageCardProps>> = ({ info, onInfoIconClick, sx, children, ...cardProps }) => {
	return (
		<Card sx={{ position: 'relative', width: 1.0, ...sx }} {...cardProps}>
			{info ? (
				<IconButton onClick={onInfoIconClick} sx={{ position: 'absolute', top: 5, right: 5 }}>
					<InfoIcon size={12} color="white" />
				</IconButton>
			) : null}
			{children}
		</Card>
	);
};
