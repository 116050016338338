import { FC, PropsWithChildren, ReactNode } from 'react';
import { CUSTOM_THEME } from '@utils';
import { Grid, GridProps, Stack, StackProps } from '@mui/material';
import { LayoutNavbar } from '../LayoutNavbar';

export interface ILayoutProposalPageProps extends StackProps {
	side: ReactNode;
	main: ReactNode;
	sideContainerProps?: GridProps;
	mainContainerProps?: GridProps;
}

export const LayoutProposalPage: FC<PropsWithChildren<ILayoutProposalPageProps>> = ({
	side,
	main,
	sideContainerProps: { sx: sideContainerSx, ...sideContainerProps } = {},
	mainContainerProps: { sx: mainContainerSx, ...mainContainerProps } = {},
	sx,
	...props
}) => {
	return (
		<Stack
			direction="column"
			sx={{
				maxHeight: 1.0,
				minHeight: 1.0,
				overflowY: 'hidden',
				background: {
					xs: 'linear-gradient(180deg, rgba(63, 63, 63, 0.6) 0%, rgba(63, 63, 63, 0.516) 100%)',
					[CUSTOM_THEME.mobileUntil]: CUSTOM_THEME.palette.background.default
				},
				...sx
			}}
			{...props}
		>
			<LayoutNavbar sx={{ display: { xs: 'flex', [CUSTOM_THEME.mobileUntil]: 'none' } }} />
			<Grid container sx={{ flexGrow: 1, overflow: 'hidden' }}>
				<Grid
					item
					xs={12}
					md={4}
					sx={{
						flexGrow: 1,
						overflowY: 'hidden',
						backgroundColor: { xs: undefined, [CUSTOM_THEME.mobileUntil]: 'grey.500' },
						...sideContainerSx
					}}
					{...sideContainerProps}
				>
					{side}
				</Grid>

				<Grid
					item
					xs={12}
					md={8}
					sx={{
						height: 1.0,
						overflow: 'hidden',
						paddingX: 3,
						...mainContainerSx
					}}
					{...mainContainerProps}
				>
					{main}
				</Grid>
			</Grid>
		</Stack>
	);
};
