import { FC, PropsWithChildren } from 'react';
import { NumberUtils, Tr } from '@utils';
import { ArrowCircleIcon } from '@components/icons';
import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { StackProps } from '@mui/system';

export interface ICardFinalPriceProps extends StackProps {
	price?: number;
	ctaButtonProps?: ButtonProps;
}

export const CardFinalPrice: FC<PropsWithChildren<ICardFinalPriceProps>> = ({
	price,
	sx,
	ctaButtonProps: { sx: ctaButtonSx, ...ctaButtonProps } = {},
	...props
}) => {
	return (
		<Stack direction="row" sx={{ paddingX: 2, width: 1.0, alignItems: 'center', justifyContent: 'space-between', ...sx }} {...props}>
			<Stack spacing={1}>
				<Typography variant="caption">
					<Tr.Proposal path="result.final-price.title" />
				</Typography>
				<Typography variant="body1" sx={{ fontWeight: 700, lineHeight: '20px' }}>
					{NumberUtils.formatAsPrice(price)}
					<Typography display="inline" variant="caption">
						<Tr.Proposal path="result.final-price.unit" />
					</Typography>
				</Typography>
			</Stack>
			<Button
				variant="contained"
				color="secondary"
				sx={{
					paddingRight: 0.5,
					paddingY: 0.5,
					...ctaButtonSx
				}}
				{...ctaButtonProps}
			>
				<Tr.Proposal path="result.final-price.cta-button" />
				<ArrowCircleIcon sx={{ marginLeft: 2 }} />
			</Button>
		</Stack>
	);
};
