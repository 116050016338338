import { Navigate } from 'react-router-dom';
import { IRoute } from '@models';
import { Proposte } from './Proposal/Proposal';
import { ProposteLeasys } from './Proposte-leasys/ProposteLeasys';

export const ROUTES: IRoute[] = [
	{
		path: '/',
		element: <Navigate to={{ pathname: '/ev' }} replace />
	},
	{
		path: '/ev',
		element: <Proposte />
	},
	// {
	// 	path: '/proposte-leasys',
	// 	element: <ProposteLeasys />
	// },
	{
		path: '*',
		element: <Navigate to={{ pathname: '/' }} replace />
	}
];
