import { FC, useEffect } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { useService } from '@hooks';
import { notificationService, NotificationType } from '@services';
import { CustomThemeProvider, HeremapsProvider, history, StoreProvider, TranslationProvider } from '@utils';
import { AppRoutes, LoadingSpinnerOverlay, Notification } from '@components/common';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactGA from 'react-ga';
import { CssBaseline } from '@mui/material';
import { filter } from 'rxjs';

const TRACKING_ID = 'UA-000000-01'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export const App: FC = () => {
	const { data: notification } = useService(() =>
		notificationService.getSubject().pipe(filter((notif) => (notif ? notif?.type !== NotificationType.ERROR : true)))
	);
	const calculateAndSaveViewportHeight = () => {
		typeof window !== 'undefined' && document.body.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
	};
	const calculateAndSaveViewportWidth = () => {
		typeof window !== 'undefined' && document.body.style.setProperty('--vw', `${window.innerWidth * 0.01}px`);
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);

		calculateAndSaveViewportHeight();
		calculateAndSaveViewportWidth();

		window.addEventListener('resize', calculateAndSaveViewportHeight);
		window.addEventListener('resize', calculateAndSaveViewportWidth);

		return () => {
			window.removeEventListener('resize', calculateAndSaveViewportHeight);
			window.removeEventListener('resize', calculateAndSaveViewportWidth);
		};
	}, []);

	return (
		<HistoryRouter history={history}>
			<StoreProvider>
				<HeremapsProvider>
					<CustomThemeProvider>
						<TranslationProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns as any}>
								<CssBaseline />
								<Notification
									open={!!notification}
									onClose={() => notificationService.clear()}
									alertProps={{ variant: 'outlined' }}
									{...notification}
								/>
								<LoadingSpinnerOverlay />
								<AppRoutes />
							</LocalizationProvider>
						</TranslationProvider>
					</CustomThemeProvider>
				</HeremapsProvider>
			</StoreProvider>
		</HistoryRouter>
	);
};
