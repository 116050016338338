import { FC, PropsWithChildren } from 'react';
import { Tr } from '@utils';
import { DriverHabit } from '@models';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SxProps } from '@mui/material/styles';

interface ISelectorDrivingHabitProps {
	value?: DriverHabit;
	onValueChange?: (val?: DriverHabit) => void;
}

export const SelectorDrivingHabit: FC<PropsWithChildren<ISelectorDrivingHabitProps>> = ({ value, onValueChange }) => {
	return (
		<ToggleButtonGroup sx={CustomToggleButtonGroupSx} value={value} onChange={(_, val) => onValueChange?.(val)} exclusive>
			<ToggleButton value={DriverHabit.URBAN}>
				<Tr.Proposal path="calculation-inputs.selector-driving-habit.urban" />
			</ToggleButton>
			<ToggleButton value={DriverHabit.COMBINED}>
				<Tr.Proposal path="calculation-inputs.selector-driving-habit.combined" />
			</ToggleButton>
			<ToggleButton value={DriverHabit.HIGHWAY}>
				<Tr.Proposal path="calculation-inputs.selector-driving-habit.highway" />
			</ToggleButton>
		</ToggleButtonGroup>
	);
};

const CustomToggleButtonGroupSx: SxProps = {
	flexDirection: 'column',
	gap: 1.5,
	borderRadius: 0,
	width: '100%',
	alignItems: 'center',

	'& .MuiToggleButtonGroup-grouped': {
		border: '2px solid rgba(255, 255, 255, 0.1) !important',
		color: '#7F7F7F',
		borderRadius: ' 100vmax !important',
		textTransform: 'capitalize',
		padding: '4px',
		width: '100%',
		maxWidth: 225
	},
	'& .Mui-selected': {
		backgroundImage: 'linear-gradient(135deg, #17EAD9 0%, #6176F4 100%)',
		color: '#FFF !important',
		border: '2px solid #FFF !important'
	}
};
