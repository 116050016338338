import { FC, PropsWithChildren, useCallback, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { Tr } from '@utils';
import { B2CService } from '@services';
import { Card, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { IVoltaageCardProps, VoltaageCard } from '@components/common';
import { useDispatch, useSelector } from 'react-redux';
import { proposalsSelector, setSelectedServices } from '@store/slices/proposal-slice';
import { ModalServiceDetail } from '../ModalServiceDetail';
import { AddIcon, CheckIcon, InfoIcon } from '@components/icons';

interface ICardServicesProps extends IVoltaageCardProps {
	services: B2CService[];
}

export const CardServices: FC<PropsWithChildren<ICardServicesProps>> = ({ services, sx, ...cardProps }) => {
	const [infoModalForService, setInfoModalForService] = useState<B2CService>();

	const dispatch = useDispatch();
	const selectedServices = useSelector(proposalsSelector('selectedServices')) || [];
	const onSelectChange = useCallback(
		(selected: boolean, service: B2CService) => {
			let newSelectedIds: string[] = [];
			if (selected) {
				newSelectedIds = [...selectedServices, service.id];
				ReactGA4.event({ category: 'Service', action: 'Added', label: `service_${service.id}_${service.name}` });
			} else {
				newSelectedIds = selectedServices.filter((serviceId) => serviceId !== service.id);
				ReactGA4.event({ category: 'Service', action: 'Removed', label: `service_${service.id}_${service.name}` });
			}
			newSelectedIds = [...new Set(newSelectedIds.filter((id) => services.find((serv) => serv.id === id)))];
			dispatch(setSelectedServices(newSelectedIds));
		},
		[selectedServices]
	);

	return (
		<>
			<VoltaageCard
				variant="outlined"
				sx={{
					backgroundColor: 'grey.700',
					flexGrow: 1,
					padding: 0,
					display: 'flex',
					width: 1.0,
					maxHeight: 1.0,
					overflowY: 'hidden',
					...sx
				}}
				{...cardProps}
			>
				<Stack direction="column" justifyContent="space-between" sx={{ width: 1.0, maxHeight: 1.0, overflowY: 'hidden' }}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							borderBottomWidth: 1,
							borderBottomStyle: 'solid',
							borderBottomColor: 'grey.400',
							paddingX: 2.5,
							paddingY: 2
						}}
					>
						<Typography variant="subtitle2">
							<Tr.Proposal path="result.services.title" />
						</Typography>
					</Stack>
					<Stack direction="column" spacing={1.25} sx={{ flexGrow: 1, maxHeight: 1.0, padding: 2, overflowY: 'auto' }}>
						{services?.map((service) => {
							const selected = selectedServices.includes(service.id);
							return (
								<Card
									key={service.id}
									variant="outlined"
									sx={{
										display: 'table',
										paddingX: 2.5,
										paddingY: 1,
										borderRadius: 2,
										borderColor: selected ? 'common.white' : undefined,
										backgroundColor: 'grey.700',
										backgroundImage: selected ? 'linear-gradient(135deg, #17EAD9 0%, #6176F4 100%)' : undefined
									}}
								>
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										<Stack direction="row" spacing={0.5} alignItems="center">
											<Typography
												variant="caption"
												lineHeight={'14px'}
												sx={{ textShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)', fontWeight: selected ? 600 : 400 }}
											>
												{service.name}
											</Typography>
											<IconButton
												onClick={() => {
													ReactGA4.event({
														category: 'Service',
														action: 'Info Clicked',
														label: `service_${service.id}_${service.name}`
													});
													setInfoModalForService(service);
												}}
											>
												<InfoIcon size={10} color="white" />
											</IconButton>
										</Stack>
										<Checkbox
											icon={<AddIcon />}
											checkedIcon={<CheckIcon />}
											checked={selected}
											onChange={(_, checked) => onSelectChange?.(checked, service)}
										/>
									</Stack>
								</Card>
							);
						})}
					</Stack>
				</Stack>
				<ModalServiceDetail
					open={!!infoModalForService}
					service={infoModalForService}
					onClose={() => setInfoModalForService(undefined)}
				/>
			</VoltaageCard>
		</>
	);
};
