import { config } from '@config';

export enum LanguageCode {
	IT = 'it',
	EN = 'en',
	FR = 'fr',
}

export interface ILanguage {
	code: LanguageCode;
	text: string;
}

export const AVAILABLE_LANGUAGES: ILanguage[] = [
	{ code: LanguageCode.FR, text: 'French' },
	{ code: LanguageCode.EN, text: 'English' },
	{ code: LanguageCode.IT, text: 'Italian' },
];
export const DEFAULT_LANGUAGE: ILanguage = (AVAILABLE_LANGUAGES.find((lang) => lang.code === config.lang) ||
	AVAILABLE_LANGUAGES.find((lang) => lang.code === LanguageCode.IT)) as ILanguage;
