import { BaseEntity } from '@models/entity';

export class B2CEngagement extends BaseEntity {
	name?: string;
	surname?: string;
	email?: string;
	phone?: string;
	village?: string;
	address?: string;
	latitude?: number;
	longitude?: number;
}
