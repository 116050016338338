import { Type } from 'class-transformer';
import { BaseEntity } from '@models/entity/base-entity.class';
import { CarModel } from './car-model.class';

export class Stock extends BaseEntity {
	carModelId: number;

	@Type(() => CarModel)
	carModel: CarModel;

	organizationId: string;

	title?: string;
	message?: string;
	externalLink?: string;
	fullPrice: number;
	leasingPrice: number;
	insurance?: number;
	vehicleTax?: number;
	mAndR?: number;
	tires?: number;
	residiualValue?: number;
	isAvailable?: boolean;
	stockAmount?: number;
	buyToOrder?: number;
	b2cBrand?: string;
	b2cModel?: string;
	b2cVersion?: string;
}
