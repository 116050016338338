import { config } from '@config';
import { timer } from 'rxjs';
import { RxUtils, SubscriptionList } from '@utils';
import { concatMap, tap, map } from 'rxjs/operators';
import { httpService } from '@services/http';
import { logger } from '@services/logger';
import { getStore } from '@store';
import { setToken } from '@store/slices/common.slice';
import { IToken } from './token.interface';

const TOKEN_VALIDITY_CHECK_INTERVAL = 5 * 60 * 1000;

export class AuthService {
	private subscriptions: SubscriptionList & { [key: string]: any } = new SubscriptionList();
	private store = getStore().store;
	private getUrl = () => `${config.apiUrl}/b2c/trivellato`;

	constructor() {
		this.startTokenValidityChecker();
	}

	getToken() {
		return httpService.get<IToken>(this.getUrl(), 'get-token', { noAuth: true, errorHandler: 'notification' }).pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.getToken.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => this.store.dispatch(setToken(result)).payload)
		);
	}

	private startTokenValidityChecker() {
		const pipename = 'TokenValidtyChecker';
		this.subscriptions[pipename] = timer(0, TOKEN_VALIDITY_CHECK_INTERVAL)
			.pipe(concatMap(() => this.getToken()))
			.subscribe();
	}
}

export const authService = new AuthService();
