import { FC, PropsWithChildren, ReactNode } from 'react';
import { CUSTOM_THEME, NumberUtils, Tr } from '@utils';
import { GasIcon, LightningIcon, RefillIcon } from '@components/icons';
import { Typography, Stack } from '@mui/material';
import { IVoltaageModalDialogProps, VoltaageModalDialog, VoltaageModalDialogTitle } from '@components/common';

const MOBILE_ICON_SIZE = 48;

interface PriceProps {
	value?: number;
	unit: ReactNode;
	description: ReactNode;
	icon: ReactNode;
}
export const Price: FC<PropsWithChildren<PriceProps>> = ({ value, unit, description, icon }) => {
	return (
		<Stack alignItems="center" sx={{ gap: 1, flexDirection: { xs: 'row', [CUSTOM_THEME.mobileUntil]: 'column' } }}>
			{icon}
			<Stack direction="column">
				<Stack direction="row" spacing={0.5} sx={{ marginTop: 1, alignItems: 'baseline' }}>
					<Typography variant="h5">{NumberUtils.formatAsPrice(value, { prefix: '' })}</Typography>
					<Typography variant="caption" fontWeight={500}>
						€ / {unit}
					</Typography>
				</Stack>
				<Typography variant="caption" sx={{ color: 'grey.300' }}>
					{description}
				</Typography>
			</Stack>
		</Stack>
	);
};

export interface IModalSavingsDetailProps extends IVoltaageModalDialogProps {
	electricityPrice?: number;
	gasolinePrice?: number;
	dieselPrice?: number;
}

export const ModalSavingsDetail: FC<PropsWithChildren<IModalSavingsDetailProps>> = ({
	electricityPrice,
	gasolinePrice,
	dieselPrice,
	...props
}) => {
	return (
		<VoltaageModalDialog
			{...props}
			title={
				<VoltaageModalDialogTitle>
					<Tr.Proposal path="tell-me-more" />
				</VoltaageModalDialogTitle>
			}
		>
			<Stack
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					padding: { xs: 3, [CUSTOM_THEME.mobileUntil]: 8 },
					gap: { xs: 1.5, [CUSTOM_THEME.mobileUntil]: 2.5 }
				}}
			>
				<Typography variant="h6" sx={{ textAlign: 'center', fontSize: { xs: 16, [CUSTOM_THEME.mobileUntil]: 'initial' } }}>
					<Tr.Proposal path="result.savings.modal-title" />
					<br />
					<Tr.Proposal path="result.savings.modal-title-2" />
				</Typography>
				<Stack
					sx={{
						flexDirection: { xs: 'column', [CUSTOM_THEME.mobileUntil]: 'row' },
						gap: 4,
						paddingY: { xs: 3, [CUSTOM_THEME.mobileUntil]: 8 },
						justifyContent: 'center'
					}}
				>
					<Price
						value={electricityPrice}
						unit="kWh"
						description={<Tr.Proposal path="result.savings.energy-price" />}
						icon={
							<LightningIcon
								sx={{
									width: { xs: MOBILE_ICON_SIZE, [CUSTOM_THEME.mobileUntil]: 64 },
									height: { xs: MOBILE_ICON_SIZE, [CUSTOM_THEME.mobileUntil]: 64 }
								}}
							/>
						}
					/>
					<Price
						value={dieselPrice}
						unit={<Tr.Proposal path="liter" />}
						description={<Tr.Proposal path="result.savings.diesel-price" />}
						icon={
							<GasIcon
								sx={{
									width: { xs: MOBILE_ICON_SIZE, [CUSTOM_THEME.mobileUntil]: 64 },
									height: { xs: MOBILE_ICON_SIZE, [CUSTOM_THEME.mobileUntil]: 64 }
								}}
							/>
						}
					/>
					<Price
						value={gasolinePrice}
						unit={<Tr.Proposal path="liter" />}
						description={<Tr.Proposal path="result.savings.gasoline-price" />}
						icon={
							<RefillIcon
								sx={{
									width: { xs: MOBILE_ICON_SIZE, [CUSTOM_THEME.mobileUntil]: 64 },
									height: { xs: MOBILE_ICON_SIZE, [CUSTOM_THEME.mobileUntil]: 64 }
								}}
							/>
						}
					/>
				</Stack>
			</Stack>
		</VoltaageModalDialog>
	);
};
