import { Type } from 'class-transformer';

export abstract class BaseEntity {
	id: string;

	@Type(() => Date)
	createdAt: Date;

	@Type(() => Date)
	updatedAt: Date;
}
