import { FC, PropsWithChildren } from 'react';
import { CUSTOM_THEME, Tr } from '@utils';
import { Typography, Stack, Link } from '@mui/material';
import { IVoltaageModalDialogProps, VoltaageModalDialog, VoltaageModalDialogTitle } from '@components/common';
import { TreeIllustration } from '@components/icons';

export interface IModalCO2ReductionProps extends IVoltaageModalDialogProps {
	treesSavedPerYear?: number;
	co2Total?: number;
}

export const ModalCO2Reduction: FC<PropsWithChildren<IModalCO2ReductionProps>> = ({ treesSavedPerYear: _1, co2Total: _2, ...props }) => {
	return (
		<VoltaageModalDialog
			{...props}
			title={
				<VoltaageModalDialogTitle>
					<Tr.Proposal path="tell-me-more" />
				</VoltaageModalDialogTitle>
			}
		>
			<Stack direction="column" spacing={5} sx={{ padding: 8, alignItems: 'center', justifyContent: 'center' }}>
				<TreeIllustration />
				<Typography variant="h6" sx={{ textAlign: 'center', fontSize: { xs: 16, [CUSTOM_THEME.mobileUntil]: 'initial' } }}>
					<Tr.Proposal path="result.co2reduction.modal-firstline" withInsecureTemplating />
				</Typography>
				{/* <Typography variant="h6" sx={{ textAlign: 'center', fontSize: { xs: 16, [CUSTOM_THEME.mobileUntil]: 'initial' } }}>
					<Tr.Proposal path="result.co2reduction.modal-secondline" withInsecureTemplating />
				</Typography> */}
			</Stack>

			<Typography variant="caption" sx={{ position: 'absolute', bottom: 17, right: 35 }}>
				<Tr.Proposal path="result.co2reduction.source" />
				{': '}
				<Link
					href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#seedlings"
					target="_blank"
					underline="hover"
					sx={{
						fontWeight: 'inherit',
						fontSize: 'inherit',
						color: CUSTOM_THEME.extraColors.voltaageBlue,
						textDecorationColor: CUSTOM_THEME.extraColors.voltaageBlue
					}}
				>
					US EPA
				</Link>
			</Typography>
		</VoltaageModalDialog>
	);
};
