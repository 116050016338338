import { FC, PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { DriverHabit, FuelType, Stock } from '@models';
import { CUSTOM_THEME, NumberUtils, Tr } from '@utils';
import { Stack, Typography, Button, Grid, Divider, GridProps } from '@mui/material';
import { BateryIcon, DoorIcon, GearIcon, RechargeIcon, SeatIcon, SmallRoadIcon, SpeedIcon } from '@components/icons';
import { ImageSlider, IVoltaageCardProps, VoltaageCard } from '@components/common';
import { ModalVehicleDetail } from '../ModalVehicleDetail';
import { VehicleGreenStatusLabel } from '../VehicleGreenStatusLabel';

interface VehicleInfoWithIcon {
	icon: ReactNode;
	content: ReactNode;
	containerProps?: GridProps;
}

export interface ICardVehicleDetailProps extends IVoltaageCardProps {
	stock?: Partial<Stock>;
	images?: string[];
	habit?: DriverHabit;
}

export const CardVehicleDetail: FC<PropsWithChildren<ICardVehicleDetailProps>> = ({ stock, images, habit, sx, ...cardProps }) => {
	const { b2cBrand = '', b2cVersion = '' } = stock || {};
	const { brand = '', version = '', motorFuelType } = stock?.carModel || {};
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const footerInfos: VehicleInfoWithIcon[] = useMemo(
		() =>
			[
				{
					icon: <SeatIcon />,
					content: NumberUtils.formatAsNumber(stock?.carModel?.seats, { decimalScale: 0 }),
					containerProps: { xs: 2, sm: 4, [CUSTOM_THEME.mobileUntil]: 2 }
				},
				{
					icon: <BateryIcon />,
					content: NumberUtils.formatAsNumber(stock?.carModel?.batteryCapacity, { decimalScale: 0, suffix: 'kWh' }),
					containerProps: { xs: 5, sm: 4, [CUSTOM_THEME.mobileUntil]: 5 }
				},
				{
					icon: <SpeedIcon />,
					content: NumberUtils.formatAsNumber(stock?.carModel?.maxSpeed, { decimalScale: 0, suffix: 'km/h' }),
					containerProps: { xs: 5, sm: 4, [CUSTOM_THEME.mobileUntil]: 5 }
				},
				{
					icon: <DoorIcon />,
					content: NumberUtils.formatAsNumber(stock?.carModel?.doors, { decimalScale: 0 }),
					containerProps: { xs: 2, sm: 4, [CUSTOM_THEME.mobileUntil]: 2 }
				},
				{
					icon: <GearIcon />,
					content: stock?.carModel?.gearType,
					containerProps: { xs: 5, sm: 4, [CUSTOM_THEME.mobileUntil]: 5 }
				},
				motorFuelType && [FuelType.ELECTRIC, FuelType.HEV_DIESEL, FuelType.HEV_GASOLINE].includes(motorFuelType)
					? {
							icon: <SmallRoadIcon />,
							content: NumberUtils.formatAsNumber(
								(stock?.carModel?.getConsumption(habit || DriverHabit.COMBINED) || 0) * 10,
								{
									decimalScale: 0,
									suffix: 'Wh/km'
								}
							),
							containerProps: { xs: 5, sm: 4, [CUSTOM_THEME.mobileUntil]: 5 }
					  }
					: undefined
			].filter(Boolean) as VehicleInfoWithIcon[],
		[stock, motorFuelType]
	);

	return stock ? (
		<VoltaageCard variant="outlined" sx={{ backgroundColor: 'grey.700', flexGrow: 1, padding: 0, ...sx }} {...cardProps}>
			<Stack direction="column" justifyContent="space-between" sx={{ height: 1.0 }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={1.25}
					sx={{
						width: 'calc(100% - 20px)',
						borderBottomWidth: 1,
						borderBottomStyle: 'solid',
						borderBottomColor: 'grey.400',
						marginX: 'auto',
						paddingX: 1.25,
						paddingY: 1.5
					}}
				>
					<Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
						{[b2cBrand || brand, b2cVersion || version].filter(Boolean).join(' - ')}
					</Typography>
					<VehicleGreenStatusLabel fuelType={stock.carModel?.motorFuelType} />
				</Stack>
				<ImageSlider
					images={images}
					index={currentImageIndex}
					onIndexChange={setCurrentImageIndex}
					onImageClick={() => setIsModalOpen(true)}
					sx={{ flexGrow: 1, paddingX: 2.5, paddingY: 1, maxHeight: 1.0, overflow: 'hidden' }}
				/>

				<Stack direction="column" spacing={1.5} divider={<Divider />}>
					<Button
						onClick={() => {
							setIsModalOpen(true);
							ReactGA4.event({ category: 'Vehicle', action: 'Details Clicked' });
						}}
						sx={{
							marginLeft: 'auto',
							marginRight: 2.5,
							fontSize: '12px',
							fontWeight: 600,
							textTransform: 'capitalize',
							color: 'info.500'
						}}
					>
						<Tr.Proposal path="result.vehicle-detail.more-button" />
					</Button>
					<Stack direction="column" spacing={0.5} sx={{ paddingX: 2.5, paddingBottom: 2.5 }}>
						<Typography variant="caption" sx={{ color: 'grey.200' }}>
							<Tr.Proposal path="result.vehicle-detail.general-informations" />
						</Typography>
						<Grid container justifyContent="center" rowSpacing={2} sx={{ minHeight: 65 }}>
							{footerInfos.map(({ content, icon, containerProps }, i) => (
								<Grid key={`vehicle-footer-info-${i}`} item {...containerProps}>
									<Stack direction="row" spacing={1.25} alignItems="center" justifyContent="center">
										{icon}
										<Typography variant="caption" sx={{ letterSpacing: '0.04em' }}>
											{content}
										</Typography>
									</Stack>
								</Grid>
							))}
						</Grid>
					</Stack>
				</Stack>
			</Stack>

			<ModalVehicleDetail
				stockTitle={stock.title}
				stockMessage={stock.message}
				images={images}
				imageIndex={currentImageIndex}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onNext={() => setCurrentImageIndex(Math.min(currentImageIndex + 1, (images?.length || 0) - 1))}
				onPrevious={() => setCurrentImageIndex(Math.max(0, currentImageIndex - 1))}
			/>
		</VoltaageCard>
	) : null;
};
