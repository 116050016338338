import { config } from '@config';
import { concatMap, tap } from 'rxjs/operators';
import { RxUtils } from '@utils';
import { httpService } from '@services/http';
import { logger } from '@services/logger';
import { B2CFindMoreRequest } from './b2c-find-more-request.dto';

export class B2CFindMoreService {
	private getUrl = () => `${config.apiUrl}/b2c/trivellato`;

	findMore(request: B2CFindMoreRequest) {
		return httpService.post<unknown>(this.getUrl(), 'find-more', { body: request, errorHandler: 'notification' }).pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.findMore.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty')))
		);
	}
}

export const b2cFindMoreService = new B2CFindMoreService();
