import { FC, PropsWithChildren } from 'react';
import { CUSTOM_THEME, Tr } from '@utils';
import { Typography, Stack, Divider, Box } from '@mui/material';
import { IVoltaageModalDialogProps, VoltaageModalDialog } from '@components/common';
import Illustration from '@assets/illustrations/generating-result.svg';

export interface IModalFindMoreRequestAnimationProps extends IVoltaageModalDialogProps {}

export const ModalFindMoreRequestAnimation: FC<PropsWithChildren<IModalFindMoreRequestAnimationProps>> = ({ ...props }) => {
	return (
		<VoltaageModalDialog {...props} noCloseButton>
			<Stack
				sx={{
					flexDirection: { xs: 'column', [CUSTOM_THEME.mobileUntil]: 'row' },
					paddingX: { xs: 5, [CUSTOM_THEME.mobileUntil]: 10 },
					paddingY: 6,
					gap: 6
				}}
			>
				<Box
					component="img"
					src={Illustration}
					sx={{ minWidth: { xs: 'unset', [CUSTOM_THEME.mobileUntil]: 188 }, maxWidth: 200, marginX: 'auto' }}
				/>
				<Stack direction="column" spacing={3} sx={{ width: 1.0 }}>
					<Typography sx={{ fontWeight: 'bold' }}>
						<Tr.Proposal path="result.final-price.reqeust-animation-title" />
					</Typography>
					<Divider />
					<Typography>
						<Tr.Proposal path="result.final-price.reqeust-animation-content" />
					</Typography>
				</Stack>
			</Stack>
		</VoltaageModalDialog>
	);
};
