import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/types';
import { DEFAULT_LANGUAGE, LanguageCode } from '@models';
import { IToken, IUser } from '@services';

export interface ICommonState {
	language: LanguageCode;
	loggedinUser?: Maybe<Partial<IUser>>;
	token?: Maybe<IToken>;
}

const initialState: ICommonState = {
	language: DEFAULT_LANGUAGE.code,
	loggedinUser: null,
	token: null,
};

export const {
	actions: { setLanguage, setLoggedinUser, setToken },
	reducer,
} = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setLanguage: (state, data: PayloadAction<LanguageCode>) => {
			state.language = data.payload || initialState.language;
		},
		setLoggedinUser: (state, data: PayloadAction<Maybe<Partial<IUser>>>) => {
			state.loggedinUser = JSON.parse(JSON.stringify(data.payload));
		},
		setToken: (state, data: PayloadAction<Maybe<IToken>>) => {
			state.token = data.payload;
		},
	},
});

export const languageSelector = (state: RootState) => state.Common.language || initialState.language;
export const loggedinUserSelector = (state: RootState) => state.Common.loggedinUser;
export const tokenSelector = (state: RootState) => state.Common.token;
