import { FC, PropsWithChildren } from 'react';
import { InfoIcon } from '@components/icons';
import { Stack, Typography } from '@mui/material';
import { CUSTOM_THEME } from '@utils/index';

export interface IVoltaageModalDialogTitleProps {}

export const VoltaageModalDialogTitle: FC<PropsWithChildren<IVoltaageModalDialogTitleProps>> = ({ children }) => {
	return (
		<Stack direction="row" alignItems="center" spacing={1.25}>
			<InfoIcon size={16} color={CUSTOM_THEME.palette.background.default} />
			<Typography
				variant="subtitle1"
				sx={{ textTransform: 'uppercase', fontSize: { xs: 12, [CUSTOM_THEME.mobileUntil]: 'initial' } }}
			>
				{children}
			</Typography>
		</Stack>
	);
};
