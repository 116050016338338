import { FC, PropsWithChildren } from 'react';
import { CUSTOM_THEME } from '@utils';
import { Stack, StackProps } from '@mui/material';
import { LogoIcon } from '@components/icons';

export interface ILayoutNavbarProps extends StackProps {}

export const LayoutNavbar: FC<PropsWithChildren<ILayoutNavbarProps>> = ({ sx, ...props }) => {
	return (
		<Stack
			sx={{
				minHeight: { xs: 64, [CUSTOM_THEME.mobileUntil]: 80 },
				maxHeight: { xs: 64, [CUSTOM_THEME.mobileUntil]: 80 },
				alignItems: 'center',
				justifyContent: 'center',
				position: 'relative',
				borderBottom: '2px solid rgba(36, 36, 36, 0.3)',
				...sx
			}}
			{...props}
		>
			<LogoIcon />
		</Stack>
	);
};
