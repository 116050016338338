import { FC, PropsWithChildren, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { Divider, LinearProgress, linearProgressClasses, Stack, Typography } from '@mui/material';
import { CUSTOM_THEME, NumberUtils, Tr } from '@utils';
import { IVoltaageCardProps, VoltaageCard, VoltaageSwitchButton } from '@components/common';
import dollarBag from '../../../assets/icons/dollarBag.svg';
import { useDispatch, useSelector } from 'react-redux';
import { proposalsSelector, setHasSolarPanel } from '@store/slices/proposal-slice';
import { ModalSavingsDetail } from '../ModalSavingsDetail';
import { useMobile } from '@hooks/useMobile';

export interface ICardSavingsProps extends IVoltaageCardProps {
	saving?: number;
	electricityPrice?: number;
	gasolinePrice?: number;
	dieselPrice?: number;
}

export const CardSavings: FC<PropsWithChildren<ICardSavingsProps>> = ({
	saving = 0,
	electricityPrice,
	gasolinePrice,
	dieselPrice,
	sx,
	...cardProps
}) => {
	const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
	const isMobile = useMobile();
	const dispatch = useDispatch();
	const hasSolarPanel = useSelector(proposalsSelector('hasSolarPanel'));

	return (
		<>
			<VoltaageCard
				sx={{ backgroundImage: 'linear-gradient(135deg, #17EAD9 0%, #6176F4 100%)', paddingY: 2.5, ...sx }}
				elevation={0}
				info
				onInfoIconClick={() => {
					ReactGA4.event({ category: 'Savings', action: 'Info Clicked' });
					setIsInfoModalOpen(true);
				}}
				{...cardProps}
			>
				<Stack direction="column" spacing={1.25}>
					<Stack direction="column" spacing={1.25} sx={{ paddingX: 2.5 }}>
						<Stack
							alignItems="baseline"
							sx={{ gap: 0.5, flexDirection: { xs: 'column', sm: 'row', [CUSTOM_THEME.mobileUntil]: 'row' } }}
						>
							{!isMobile && <img style={{ width: 32, height: 32, marginRight: 1 }} src={dollarBag} />}
							<Typography variant="h6" sx={{ fontWeight: 600, lineHeight: '24px' }}>
								{NumberUtils.formatAsPrice(saving)}
							</Typography>
							<Typography variant="caption" sx={{ lineHeight: '14px' }}>
								<Tr.Proposal path="result.savings.savings-yearly" />
							</Typography>
						</Stack>
						<LinearProgress
							variant="determinate"
							value={80}
							sx={{
								height: 4,
								borderRadius: 5,
								[`&.${linearProgressClasses.colorPrimary}`]: {
									backgroundColor: 'info.700'
								},
								[`& .${linearProgressClasses.bar}`]: {
									borderRadius: 5,
									backgroundColor: 'common.white'
								}
							}}
						/>

						<Typography variant="caption">
							<Tr.Proposal
								path={
									saving >= 0
										? 'result.savings.description-saving-positive'
										: 'result.savings.description-saving-negative'
								}
								withInsecureTemplating
							/>
						</Typography>
					</Stack>
					<Divider light />

					<Stack
						direction="row"
						sx={{ alignItems: 'center', justifyContent: 'space-between', gap: 2, paddingX: 2.5, paddingTop: 1 }}
					>
						<Typography fontSize="10px" lineHeight={'12px'} fontWeight={600} textTransform="uppercase">
							<Tr.Proposal path="result.savings.with-solar-panel" />
						</Typography>
						<VoltaageSwitchButton value={hasSolarPanel || false} onChange={(val) => dispatch?.(setHasSolarPanel(val))} />
					</Stack>
				</Stack>
			</VoltaageCard>
			<ModalSavingsDetail
				electricityPrice={electricityPrice}
				gasolinePrice={gasolinePrice}
				dieselPrice={dieselPrice}
				open={isInfoModalOpen}
				onClose={() => setIsInfoModalOpen(false)}
			/>
		</>
	);
};
