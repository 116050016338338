import { Breakpoint, buttonClasses, createTheme, Theme } from '@mui/material';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		light: true;
	}
}

interface CustomThemeProperties {
	mobileUntil: Breakpoint;
	contentSize: Breakpoint;
	extraColors: {
		voltaageBlue: string;
	};
}

export type CustomThemeDefinition = Theme & CustomThemeProperties;

// created by using Material-UI Theme Creator
// See: https://bareynol.github.io/mui-theme-creator
export const CUSTOM_THEME: CustomThemeDefinition = Object.assign<Theme, CustomThemeProperties>(
	createTheme({
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: 'none',
						borderRadius: 25,
						minWidth: 48,
						paddingTop: 6,
						paddingBottom: 6,
						paddingLeft: 24,
						paddingRight: 24,
					},
					containedSecondary: {
						background: 'linear-gradient(135deg, #8B62FD 0%, #6435F4 100%)',
						color: '#ffffff',
						boxShadow: '0px 10px 24px -8px rgba(139, 98, 253, 0.5)',
						borderRadius: 24,
						'&:hover': {
							boxShadow: '0px 10px 24px -3px rgba(139, 98, 253, 0.5)',
						},
						[`&.${buttonClasses.disabled}`]: {
							opacity: 0.5,
						},
					},
					sizeSmall: {
						lineHeight: '22px',
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						'&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.1)',
						},
					},
				},
			},
			MuiAutocomplete: {
				defaultProps: { componentsProps: { paper: { elevation: 4, sx: { borderRadius: 2 } } } },
				styleOverrides: {
					input: {
						padding: '8px 12px !important',
					},
					option: {
						fontSize: 16,
						'&.Mui-focused': {
							backgroundColor: '#6176f4 !important',
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: 8,
						backgroundColor: '#f6f6f6',
						color: '#000',
						border: '1px solid #979797 !important',
						outline: 'none',
						'&.Mui-focused': {
							outline: 'none',
							boxShadow: '0 0 0 3px #6176f4',
						},
						'&.Mui-focused fieldset': {
							border: 'none',
						},
						'&.Mui-disabled': {
							backgroundColor: 'rgba(0, 0, 0, 0.06)',
							color: 'rgba(0, 0, 0, 0.6)',
							'& input': {
								WebkitTextFillColor: 'currentColor',
							},
						},
						input: {
							caretColor: '#343434',
							padding: '8px 12px !important',
							fontSize: '14px',
							'&::placeholder': {
								color: '#979797',
							},
							'&:-webkit-autofill': {
								'-webkit-box-shadow': '0 0 0 1000px #f6f6f6 inset',
								'-webkit-text-fill-color': '#000',
								caretColor: '#343434',
							},
						},
					},
					colorSecondary: {
						backgroundColor: 'rgba(36, 36, 36, 0.03)',
						color: '#fff',
						'&.Mui-disabled': {
							backgroundColor: 'rgba(0, 0, 0, 0.06)',
							color: 'rgba(0, 0, 0, 0.6)',
						},
					},
					sizeSmall: {
						input: {
							padding: '6px 9px !important',
							fontSize: '12px',
						},
					},
				},
			},
			// 	MuiTooltip: {
			// 		styleOverrides: {
			// 			tooltip: {
			// 				backgroundColor: '#6176f4',
			// 				padding: '24px 32px',
			// 				borderRadius: 8,
			// 				fontSize: 14,
			// 				fontWeight: 600,
			// 				color: '#ffffff',
			// 			},
			// 			arrow: {
			// 				color: '#6176f4',
			// 			},
			// 		},
			// 	},
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundColor: '#2f2f2f',
					},
					rounded: {
						borderRadius: 10,
					},
				},
			},
			MuiSlider: {
				styleOverrides: {
					root: {
						color: '#FFFFFF',
						height: 4,
						'&.Mui-focusVisible': {
							boxShadow: 'none',
						},
					},
					track: {
						border: 'none',
						color: '#6435F4',
					},
					rail: {
						backgroundColor: '#FFFFFF',
						opacity: 0.8,
					},
					thumb: {
						height: 24,
						width: 24,
						backgroundColor: 'none',
						backgroundImage: 'linear-gradient(135deg, #17EAD9 0%, #6176F4 100%)',
						border: '2px solid currentColor',
						boxShadow: 'none',
						'&:hover': {
							boxShadow: '-6px -6px 20px rgba(79, 234, 223, 0.15), 6px 6px 20px rgba(97, 118, 244, 0.3)',
						},
						transition: 'none',
					},
					valueLabel: {
						lineHeight: 1.2,
						fontSize: 12,
						padding: 8,
						backgroundColor: '#6435F4',
					},
				},
			},
			// 	MuiAlert: {
			// 		styleOverrides: {
			// 			outlined: {
			// 				border: 'solid 1px #e0e0e0',
			// 			},
			// 			message: {
			// 				padding: 0,
			// 			},
			// 			icon: {
			// 				padding: 0,
			// 			},
			// 			action: {
			// 				paddingTop: 0,
			// 			},
			// 		},
			// 	},
			MuiDivider: {
				styleOverrides: {
					root: {
						borderColor: '#444444',
					},
					light: {
						borderColor: '#ffffff26',
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						background: '#2f2f2f',
						borderRadius: '100vmax',
						outline: '2px solid #444444',
						minHeight: 42,
						padding: 4,
						marginTop: 8,
						marginBottom: 8,
						gap: 2,
						textTransform: 'uppercase',
					},
					indicator: {
						display: 'none',
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						width: '50%',
						color: '#6d6d6d',
						fontWeight: '700',
						fontSize: '11px',

						'&.MuiButtonBase-root': {
							padding: '8px',
							minHeight: 'unset',
						},

						'&.Mui-selected': {
							color: 'white',
							background: '#6176F4',
							borderRadius: '100vmax',
							border: '2px solid #7184f5',
						},
						'&:disabled': {
							cursor: 'not-allowed',
							pointerEvents: 'all',
						},
					},
				},
			},
			MuiChip: {
				styleOverrides: {
					sizeSmall: {
						padding: '5px',
						fontSize: '10px',
						fontWeight: 700,
						letterSpacing: '0.12em',
					},
					colorSuccess: {
						backgroundColor: '#415435',
						color: '#8AEA4F',
					},
					colorError: {
						backgroundColor: '#482225',
						color: '#FF8D94',
					},
				},
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						'.MuiPaper-root': {
							backgroundColor: '#ffffff',
							color: '#000000',
						},
					},
					paperWidthLg: {
						width: '70%',
					},
				},
			},
			MuiLink: {
				styleOverrides: {
					root: {
						fontSize: '12px',
						fontWeight: 600,
						color: '#4FE9DF',
						textDecorationColor: '#4FE9DF',
					},
				},
			},
		},
		palette: {
			mode: 'dark',
			grey: {
				'50': '#f6f6f6',
				// '100': '#e7e7e7',
				'200': '#979797', // adapted
				'300': '#6B6B6B', // adapted
				'400': '#444444', // adapted
				'500': '#343434', // adapted
				// '600': '#5c5c5c',
				'700': '#2f2f2f', // adapted
				// '800': '#343434',
				'900': '#1B1B1B', // adapted
			},
			primary: {
				main: '#6435F4',
			},
			// secondary: {
			// 	main: '#000000',
			// },
			background: {
				default: '#242424', // adapted
				// paper: '#ffffff',
			},
			text: {
				primary: '#ffffff', // adapted
				// secondary: '#556ee6',
			},
			success: {
				main: '#8AEA4F',
			},
			error: {
				main: '#fb4d3d',
				200: '#fedacf',
			},
			// warning: {
			// 	main: '#f1b44c',
			// 	900: '#b07512',
			// 	200: '#fcf4e6',
			// },
			info: {
				'500': '#4FE9DF', // adapted
				'700': '#8ABDEB', // adapted
			},
		},
		typography: {
			fontSize: 16,
			fontFamily: [
				'Montserrat',
				'-apple-system',
				'BlinkMacSystemFont',
				'Segoe UI',
				'Roboto',
				'Oxygen',
				'Ubuntu',
				'Cantarell',
				'Fira Sans',
				'Droid Sans',
				'Helvetica Neue',
				'sans-serif',
			].join(', '),
			allVariants: {
				fontWeight: 500,
				wordBreak: 'break-word',
			},
			// h1: {
			// 	fontSize: 77,
			// },
			// h2: {
			// 	fontSize: 38,
			// },
			h3: {
				// adapted
				fontSize: 28,
				fontWeight: 600,
			},
			h4: {
				// adapted
				fontSize: 24,
				fontWeight: 700,
			},
			h5: {
				// adapted
				fontSize: 20,
				fontWeight: 700,
			},
			subtitle1: {
				// adapted
				fontSize: 16,
				lineHeight: '1em',
				letterSpacing: '0.08em',
			},
			subtitle2: {
				// adapted
				fontSize: 12,
				fontWeight: 600,
				lineHeight: '14px',
			},
			body1: {
				fontSize: 16,
			},
			body2: {
				fontSize: 14,
			},
			caption: {
				// adapted
				fontSize: 12,
				lineHeight: '14px',
			},
		},
	}),
	{
		mobileUntil: 'md', // adapted
		contentSize: 'lg',
		extraColors: {
			voltaageBlue: '#6176F4', // adapted
		},
	}
);
