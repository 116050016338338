import { FC, PropsWithChildren } from 'react';
import { Tr } from '@utils';
import { FuelType } from '@models';
import { Chip, ChipProps } from '@mui/material';
import { HeartIcon } from '@components/icons';

const GREEN_FUEL_TYPES: FuelType[] = [FuelType.ELECTRIC, FuelType.PHEV_DIESEL, FuelType.PHEV_GASOLINE];

export interface IVehicleGreenStatusLabelProps extends ChipProps {
	fuelType?: FuelType;
}

export const VehicleGreenStatusLabel: FC<PropsWithChildren<IVehicleGreenStatusLabelProps>> = ({ fuelType, sx, ...props }) => {
	const isGreen = fuelType ? GREEN_FUEL_TYPES.includes(fuelType) : false;
	return fuelType ? (
		<Chip
			sx={{
				display: 'flex',
				alignItems: 'center',
				textTransform: 'uppercase',
				...sx
			}}
			label={<Tr.Proposal path={isGreen ? 'green' : 'non-green'} />}
			size="small"
			color={isGreen ? 'success' : 'error'}
			icon={isGreen ? <HeartIcon /> : undefined}
			{...props}
		/>
	) : null;
};
