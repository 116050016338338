export enum HeremapsServiceType {
	SEARCH = 'search',
}

export enum HeremapsPlaceSearchServiceType {
	DISCOVER = 'discover',
	GEOCODE = 'geocode',
	AUTOCOMPLETE = 'autocomplete',
	AUTOSUGGEST = 'autosuggest',
	BROWSE = 'browse',
	LOOKUP = 'lookup',
	REVGEOCODE = 'revgeocode',
}
