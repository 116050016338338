import { FC, PropsWithChildren, useEffect, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { B2CEngagement } from '@services';
import { CUSTOM_THEME, Tr } from '@utils';
import { useMobile } from '@hooks';
import { Box, Button, ClickAwayListener, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { FormikTextfield, IVoltaageModalDialogProps, Logo, VoltaageCheckbox, VoltaageModalDialog } from '@components/common';
import illustration from '@assets/illustrations/engagement-modal-illustration.svg';
import illustrationMobile from '@assets/illustrations/engagement-modal-illustration-mobile.svg';
import { InfoIcon } from '@components/icons';

type FormFields = Extendable<{
	name?: string;
	surname?: string;
	email?: string;
	phone?: string;
	village?: string;
}>;

export interface IModalEngagementFormProps extends IVoltaageModalDialogProps {
	value?: Partial<B2CEngagement>;
	onValueChange?: (val?: Partial<B2CEngagement>) => void;
}

export const ModalEngagementForm: FC<PropsWithChildren<IModalEngagementFormProps>> = ({ value, open, onValueChange, ...props }) => {
	const { t } = useTranslation('proposal');
	const isMobile = useMobile();
	const [accepted, setAccepted] = useState(false);
	const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

	const formik = useFormik<FormFields>({
		initialValues: {
			name: value?.name || '',
			surname: value?.surname || '',
			email: value?.email || '',
			phone: value?.phone || '',
			village: value?.village || ''
		},
		enableReinitialize: true,
		validateOnChange: true,
		validateOnMount: true,
		validationSchema: yup.object({
			name: yup.string().required(t('field-required')),
			surname: yup.string().required(t('field-required')),
			email: yup.string().email().required(t('field-required')),
			phone: yup.string().optional().required(t('field-required')),
			village: yup.string().optional().required(t('field-required'))
		}),
		onSubmit: ({ name, surname, email, phone, village }, api) => {
			onValueChange?.({
				name,
				surname,
				email,
				phone,
				village
			});
			api.setSubmitting(false);
			ReactGA4.event({
				category: 'Engagement Form',
				action: 'Engagement Form Submitted',
				label: `Name_${name || ''};Surname_${surname || ''};Email_${email || ''};Phone_${phone || ''};Village_${village || ''}`
			});
		}
	});
	const { submitForm, handleSubmit } = formik;
	const disabled = !formik.isValid || formik.isSubmitting || !accepted;

	useEffect(() => {
		open && ReactGA4.event({ category: 'Engagement Form', action: 'Engagement Form Displayed' });
	}, [open]);

	return (
		<VoltaageModalDialog open={open} noCloseButton containerProps={{ sx: { display: 'flex' } }} {...props}>
			<Box sx={{ display: 'flex', flexDirection: { xs: 'column', [CUSTOM_THEME.mobileUntil]: 'row' }, width: 1.0 }}>
				<Box
					sx={{
						overflow: 'hidden',
						display: 'flex',
						flexBasis: { xs: 'unset', [CUSTOM_THEME.mobileUntil]: '50%' },
						minWidth: { xs: 'unset', [CUSTOM_THEME.mobileUntil]: '50%' },
						maxWidth: { xs: 'unset', [CUSTOM_THEME.mobileUntil]: '50%' },
						position: 'relative'
					}}
				>
					<Box
						component="img"
						src={isMobile ? illustrationMobile : illustration}
						sx={{
							width: { xs: 1.0, [CUSTOM_THEME.mobileUntil]: 'auto' },
							height: { xs: 'auto', [CUSTOM_THEME.mobileUntil]: 1.0 }
						}}
					/>
					{isMobile && (
						<Box
							sx={{
								position: 'absolute',
								inset: 0,
								background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)'
							}}
						/>
					)}
				</Box>
				<Box
					sx={{
						overflow: 'hidden',
						display: 'flex',
						flexBasis: { xs: 'unset', [CUSTOM_THEME.mobileUntil]: '50%' },
						minWidth: { xs: 'unset', [CUSTOM_THEME.mobileUntil]: '50%' },
						maxWidth: { xs: 'unset', [CUSTOM_THEME.mobileUntil]: '50%' },
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Stack
						sx={{
							height: 1.0,
							paddingLeft: { xs: 4, [CUSTOM_THEME.mobileUntil]: 6 },
							paddingRight: { xs: 4, [CUSTOM_THEME.mobileUntil]: 9 },
							paddingTop: { xs: 1, [CUSTOM_THEME.mobileUntil]: 5 },
							paddingBottom: 5,
							justifyContent: 'space-between'
						}}
						spacing={1}
					>
						<Logo
							svgIconProps={{
								sx: {
									height: { xs: 30, [CUSTOM_THEME.mobileUntil]: 50 },
									width: { xs: 96, [CUSTOM_THEME.mobileUntil]: 160 }
								}
							}}
						/>
						<Typography variant={isMobile ? 'caption' : 'h4'} sx={{ fontWeight: 700, lineHeight: '150%' }}>
							<Tr.Proposal path="engagement.title" />
						</Typography>
						<Stack direction="column" spacing={0}>
							<Box component="form" onSubmit={handleSubmit} sx={{ flexGrow: 1, overflowY: 'hidden', padding: 1, width: 1.0 }}>
								<Stack spacing={1} sx={{ width: 1.0 }}>
									<FormikTextfield
										formikApi={formik}
										variant="outlined"
										size={isMobile ? 'small' : 'medium'}
										name="name"
										placeholder={t('engagement.name-placeholder')}
									/>
									<FormikTextfield
										formikApi={formik}
										variant="outlined"
										name="surname"
										size={isMobile ? 'small' : 'medium'}
										placeholder={t('engagement.surname-placeholder')}
									/>
									<FormikTextfield
										formikApi={formik}
										variant="outlined"
										name="email"
										size={isMobile ? 'small' : 'medium'}
										placeholder={t('engagement.email-placeholder')}
									/>
									<FormikTextfield
										formikApi={formik}
										variant="outlined"
										name="phone"
										size={isMobile ? 'small' : 'medium'}
										placeholder={t('engagement.phone-placeholder')}
									/>
									<FormikTextfield
										formikApi={formik}
										variant="outlined"
										name="village"
										size={isMobile ? 'small' : 'medium'}
										placeholder={t('engagement.village-placeholder')}
									/>
								</Stack>
							</Box>

							<Stack direction="row" spacing={0.5} alignItems="center">
								<Typography variant="caption" sx={{ paddingLeft: 1 }}>
									<Link
										href="https://www.trivellato.it/landing/privacy-policy-sito-web-informativa"
										target="_blank"
										underline="hover"
										sx={{
											fontWeight: 'inherit',
											fontSize: 'inherit',
											color: CUSTOM_THEME.extraColors.voltaageBlue,
											textDecorationColor: CUSTOM_THEME.extraColors.voltaageBlue
										}}
									>
										<Tr.Proposal path="engagement.privacy-policy" />
									</Link>
								</Typography>
								<ClickAwayListener onClickAway={() => setPrivacyPolicyOpen(false)}>
									<div>
										<Tooltip
											placement="top"
											PopperProps={{ disablePortal: true }}
											onClose={() => setPrivacyPolicyOpen(false)}
											open={privacyPolicyOpen}
											disableFocusListener
											disableHoverListener
											disableTouchListener
											title={<Tr.Proposal path="engagement.privacy-policy-details" />}
										>
											<IconButton onClick={() => setPrivacyPolicyOpen((current) => !current)}>
												<InfoIcon size={16} color={CUSTOM_THEME.palette.background.default} />
											</IconButton>
										</Tooltip>
									</div>
								</ClickAwayListener>
							</Stack>
							<Stack direction="row" spacing={0.5} alignItems="center">
								<VoltaageCheckbox
									size="small"
									sx={{ maxWidth: 48, maxHeight: 48 }}
									checked={accepted}
									onChange={(_, checked) => setAccepted(checked)}
								/>
								<Typography variant="caption" sx={{ fontSize: { xs: 10, [CUSTOM_THEME.mobileUntil]: 12 } }}>
									<Tr.Proposal path="engagement.accept-contact" />
								</Typography>
							</Stack>
						</Stack>
						<Box
							sx={{ display: 'flex', paddingTop: 0.5, justifyContent: { xs: 'center', [CUSTOM_THEME.mobileUntil]: 'start' } }}
						>
							<Button
								variant="contained"
								color="secondary"
								size={isMobile ? 'small' : 'medium'}
								sx={{ textTransform: 'uppercase', minWidth: 160 }}
								disabled={disabled}
								onClick={submitForm}
							>
								<Tr.Proposal path="engagement.subscribe" />
							</Button>
						</Box>
					</Stack>
				</Box>
			</Box>
		</VoltaageModalDialog>
	);
};
