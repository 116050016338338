import { FC, PropsWithChildren, ReactNode } from 'react';
import { CloseIcon } from '@components/icons';
import { Fade, Card, Stack, Dialog, DialogProps, IconButton, CardProps, IconButtonProps } from '@mui/material';

export interface IVoltaageModalDialogProps extends Omit<DialogProps, 'children' | 'title'> {
	containerProps?: CardProps;
	title?: ReactNode;
	noCloseButton?: boolean;
	closeButtonProps?: IconButtonProps;
}

export const VoltaageModalDialog: FC<PropsWithChildren<IVoltaageModalDialogProps>> = ({
	containerProps: { sx: containerSx, ...containerProps } = {},
	title,
	noCloseButton,
	closeButtonProps: { sx: closeButtonSx, ...closeButtonProps } = {},
	children,
	...props
}) => {
	return (
		<Dialog closeAfterTransition slotProps={{ backdrop: { timeout: 500 } }} maxWidth="lg" {...props}>
			<Fade in={props.open}>
				<Card
					variant="elevation"
					elevation={0}
					sx={{
						position: 'relative',
						borderRadius: 2,
						...containerSx
					}}
					{...containerProps}
				>
					{title ? (
						<Stack
							direction="column"
							sx={{
								borderBottomWidth: 2,
								borderBottomStyle: 'solid',
								borderBottomColor: 'rgba(0, 0, 0, 0.2)',
								padding: 2.5,
								alignItems: 'center'
							}}
						>
							<Stack
								direction="row"
								sx={{ width: 1.0, alignItems: 'center', justifyContent: 'space-between', paddingRight: 4 }}
							>
								{title}
							</Stack>
						</Stack>
					) : null}
					{!noCloseButton && (
						<IconButton
							sx={{ position: 'absolute', top: 12, right: 16, zIndex: 9999999999, ...closeButtonSx }}
							onClick={() => props.onClose?.({}, 'backdropClick')}
							{...closeButtonProps}
						>
							<CloseIcon />
						</IconButton>
					)}
					{children}
				</Card>
			</Fade>
		</Dialog>
	);
};
