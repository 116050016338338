import { Type } from 'class-transformer';
import { CarModel } from '@models';

export class B2CCalculationResultItemCurrentVehicle {
	cum: number;
	price: number;
	co2: number;
	fuelCost: number;
	fuelPriceDiesel: number;
	fuelPriceGasoline: number;
	fuelPriceElectricity: number;

	@Type(() => CarModel)
	model: CarModel;

	images: string[];
}
