import { config } from '@config';
import { createContext } from 'react';

export const platform =
	typeof window.H !== 'undefined'
		? new H.service.Platform({
				apikey: config.heremapsApiKey,
		  })
		: null;

export const PlatformContext = createContext<H.service.Platform | null>(platform);
