import { FC, PropsWithChildren, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { CUSTOM_THEME, Tr } from '@utils';
import { HeremapsPlace, HeremapsPlaceSearchChargingPoint } from '@services';
import { Stack, Typography, Link, Box, IconButton } from '@mui/material';
import { IVoltaageCardProps, VoltaageCard } from '@components/common';
import { useMobile } from '@hooks/useMobile';
import { ModalChargingStationsMap } from '../ModalChargingStationsMap';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

export interface ICardChargingStationsProps extends IVoltaageCardProps {
	placeDetail?: HeremapsPlace;
	chargingStations?: HeremapsPlaceSearchChargingPoint[];
}

export const CardChargingStations: FC<PropsWithChildren<ICardChargingStationsProps>> = ({
	chargingStations = [],
	placeDetail,
	sx,
	...cardProps
}) => {
	const isMobile = useMobile();
	const [mapOpenedAt, setMapOpenedAt] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<VoltaageCard sx={{ backgroundColor: 'grey.900', paddingX: 3, paddingY: 2.5, ...sx }} elevation={0} {...cardProps}>
			<Stack
				direction={{ xs: 'row', [CUSTOM_THEME.mobileUntil]: 'column' }}
				spacing={{ xs: 0, [CUSTOM_THEME.mobileUntil]: 1 }}
				sx={{
					justifyContent: { xs: 'space-between', [CUSTOM_THEME.mobileUntil]: 'start' },
					alignItems: { xs: 'center', [CUSTOM_THEME.mobileUntil]: 'start' }
				}}
			>
				<Stack direction="row" spacing={2} sx={{ width: 1.0, alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography variant="body1" sx={{ fontWeight: 600 }}>
						<Box
							component="span"
							sx={{
								lineHeight: '24px',
								fontSize: { xs: 16, [CUSTOM_THEME.mobileUntil]: 28 },
								fontWeight: { xs: 800, [CUSTOM_THEME.mobileUntil]: 600 }
							}}
						>
							{`${chargingStations.length || 0} `}
						</Box>
						<Tr.Proposal path="result.charging-stations.title" />
					</Typography>

					<IconButton
						color="info"
						size="small"
						onClick={() => {
							setIsModalOpen(true);
							setMapOpenedAt(Date.now());
							ReactGA4.event({ category: 'Charging Station', action: 'Map Open by Icon' });
						}}
					>
						<MapOutlinedIcon color="inherit" />
					</IconButton>
				</Stack>

				<Typography variant="caption" sx={{ display: 'inline' }}>
					{!isMobile && (
						<Tr.Proposal
							path="result.charging-stations.description"
							options={{ chargingStation: chargingStations.length || 0 }}
							withInsecureTemplating
						/>
					)}
					<Link
						component="button"
						underline="always"
						sx={{ marginLeft: 0.5 }}
						onClick={() => {
							setIsModalOpen(true);
							setMapOpenedAt(Date.now());
							ReactGA4.event({ category: 'Charging Station', action: 'Map Open by Text' });
						}}
					>
						<Tr.Proposal path="result.charging-stations.open-map-button" />
					</Link>
				</Typography>
			</Stack>

			<ModalChargingStationsMap
				placeDetail={placeDetail}
				open={isModalOpen}
				onClose={() => {
					const mapTime = Date.now() - mapOpenedAt;
					setIsModalOpen(false);
					setMapOpenedAt(0);
					mapTime > 0 &&
						ReactGA4.event({ category: 'Charging Station', action: 'Spent Time on Map', label: `${Math.round(mapTime)} sec.` });
				}}
			/>
		</VoltaageCard>
	);
};
