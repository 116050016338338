import { Type } from 'class-transformer';
import { HeremapsAddress, HeremapsPoint } from '../common';

export class HeremapsPlace {
	id: string;
	title: string;
	language: string;
	resultType: string;

	@Type(() => HeremapsAddress)
	address: HeremapsAddress;

	@Type(() => HeremapsPoint)
	position: HeremapsPoint;

	mapView: {
		west: H.geo.Longitude;
		south: H.geo.Latitude;
		east: H.geo.Longitude;
		north: H.geo.Latitude;
	};
}
