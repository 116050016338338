import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RxUtils, Tr } from '@utils';
import { HeremapsPlaceSearchAutocompleteItem, heremapsPlaceSearchService } from '@services';
import { Autocomplete, autocompleteClasses, AutocompleteProps, debounce, Grid, TextField, TextFieldProps, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export interface ILocationSearchProps
	extends Partial<Omit<AutocompleteProps<HeremapsPlaceSearchAutocompleteItem, false, false, false, 'div'>, 'onChange'>> {
	inputProps?: TextFieldProps;
	value?: HeremapsPlaceSearchAutocompleteItem;
	onChange?: (selectedLocation?: HeremapsPlaceSearchAutocompleteItem) => void;
}

export const LocationSearch: FC<PropsWithChildren<ILocationSearchProps>> = ({ inputProps, value, onChange, ...props }) => {
	const { t } = useTranslation('proposal');
	const [loading, setLoading] = useState(false);
	const [selectedValue, setSelectedValue] = useState<HeremapsPlaceSearchAutocompleteItem | undefined>(value);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState<HeremapsPlaceSearchAutocompleteItem[]>();

	const search = useMemo(
		() =>
			debounce((q: string, onSuccess: (results?: HeremapsPlaceSearchAutocompleteItem[]) => void, onError: (e: unknown) => void) => {
				RxUtils.promisify(heremapsPlaceSearchService.autocomplete(q), (result) => onSuccess(result.items), onError);
			}, 500),
		[]
	);

	useEffect(() => {
		if (inputValue === '') {
			setOptions(selectedValue ? [selectedValue] : []);
			return undefined;
		}

		setLoading(true);
		search(
			inputValue,
			(opts) => {
				setOptions(opts);
				setLoading(false);
			},
			(e) => {
				console.error('Error occured:', e);
				setLoading(false);
			}
		);
	}, [selectedValue, inputValue, search]);

	useEffect(() => {
		onChange?.(selectedValue);
	}, [selectedValue]);

	useEffect(() => {
		if ((value && selectedValue?.id !== value.id) || !value) {
			setSelectedValue(value);
			setInputValue(value?.title || '');
		}
	}, [value]);

	return (
		<Autocomplete
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
			filterOptions={(x) => x}
			autoComplete
			autoHighlight
			includeInputInList
			fullWidth
			filterSelectedOptions
			value={selectedValue}
			inputValue={inputValue}
			loading={loading}
			loadingText={<Tr.Proposal path="calculation-inputs.selector-location.loading" />}
			noOptionsText={
				inputValue ? (
					<Typography sx={{ padding: '14px 16px' }}>
						<Tr.Proposal path="calculation-inputs.selector-location.no-locations" />
					</Typography>
				) : (
					''
				)
			}
			componentsProps={{
				popper: {
					sx: {
						[`& .${autocompleteClasses.noOptions}`]: {
							padding: 0
						}
					}
				}
			}}
			{...props}
			options={options || []}
			onChange={(_, newValue) => {
				setOptions(newValue ? [newValue, ...(options || [])] : options);
				setSelectedValue(newValue || undefined);
			}}
			onInputChange={(_, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(attrs) => (
				<TextField
					{...attrs}
					hiddenLabel
					placeholder={t('calculation-inputs.selector-location.location-search-placeholder')}
					variant="standard"
					color="secondary"
					{...inputProps}
				/>
			)}
			renderOption={(attrs, option) => {
				return (
					<li {...attrs}>
						<Grid container alignItems="center">
							<Grid item sx={{ display: 'flex', width: 44 }}>
								<LocationOnIcon sx={{ color: 'text.secondary' }} />
							</Grid>
							<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
								<Typography>{option.title}</Typography>
								<Typography variant="body2" color="text.secondary">
									{option.address?.label || ''}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
};
