import { config } from '@config';
import { concatMap, map, tap } from 'rxjs/operators';
import { RxUtils } from '@utils';
import { httpService } from '@services/http';
import { logger } from '@services/logger';

export class B2CStockService {
	private getUrl = () => `${config.apiUrl}/b2c/trivellato`;

	getAllBrands() {
		return httpService.get<string[]>(this.getUrl(), 'brands').pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.getAllBrands.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => (Array.isArray(result) ? result.filter(Boolean) : []))
		);
	}

	getAllModels(brand: string) {
		return httpService.get<string[]>(this.getUrl(), `brand/${brand}`).pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.getAllModels.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => (Array.isArray(result) ? result.filter(Boolean) : []))
		);
	}

	getAllVersions(brand: string, model: string) {
		return httpService.get<string[]>(this.getUrl(), `brand/${brand}/model/${model}`).pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.getAllVersions.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => (Array.isArray(result) ? result.filter(Boolean) : []))
		);
	}
}

export const b2cStockService = new B2CStockService();
