import { Type } from 'class-transformer';
import { B2CCalculationResultItemCurrentVehicle } from './b2c-calculation-result-item-current-vehicle.class';
import { B2CCalculationResultItemProposedVehicle } from './b2c-calculation-result-item-proposed-vehicle.class';

export class B2CCalculationResult {
	engagementId: string;
	@Type(() => B2CCalculationResultItemProposedVehicle)
	evModel: B2CCalculationResultItemProposedVehicle;
	// [B2CCalculationVehicleType.EV]: B2CCalculationResultItem; // TODO: javascript issue about using dynamic keys with annotations

	@Type(() => B2CCalculationResultItemProposedVehicle)
	phevModel?: B2CCalculationResultItemProposedVehicle;
	// [B2CCalculationVehicleType.PHEV]?: B2CCalculationResultItem; // TODO: javascript issue about using dynamic keys with annotations

	@Type(() => B2CCalculationResultItemCurrentVehicle)
	ice?: B2CCalculationResultItemCurrentVehicle;
}
