import { Type } from 'class-transformer';
import { PaymentRecurrence, PaymentType } from '@models';

export class B2CService {
	id: string;

	@Type(() => Date)
	createdAt: Date;

	@Type(() => Date)
	updatedAt: Date;
	name: string;
	description?: string;
	summary?: string;
	serviceType?: string;
	supplier?: string;
	organizationId: string;
	price?: number;
	available?: boolean;
	paymentRecurrence?: PaymentRecurrence;
	paymentType?: PaymentType;
	logoUrl?: string;
}
