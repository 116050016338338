import { config } from '@config';
import { of } from 'rxjs';
import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';
import { httpService } from '@services/http';
import { logger } from '@services/logger';
import { B2CEngagement } from './b2c-engagement.class';

const MOCKED = false;
const MOCK_RESPONSE: B2CEngagement = {
	id: '123456adasdasdasd',
	name: '',
	surname: '',
	email: '',
	phone: '',
	village: '',
	createdAt: new Date(),
	updatedAt: new Date(),
};

export class B2CEngagementService {
	private getUrl = () => `${config.apiUrl}/b2c/trivellato`;

	engage(payload: Partial<B2CEngagement>) {
		if (MOCKED) {
			return of(MOCK_RESPONSE);
		}

		return httpService.post<B2CEngagement>(this.getUrl(), 'engage', { body: payload, errorHandler: 'notification' }).pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.engage.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.convert(B2CEngagement, result))
		);
	}
}

export const b2cEngagementService = new B2CEngagementService();
