import { Exclude, Type } from 'class-transformer';

export class HeremapsResponse<ItemType = unknown> {
	@Exclude()
	private itemType: Function;

	@Type((options) => (options?.newObject as HeremapsResponse<ItemType>).itemType)
	items?: ItemType[];

	constructor(itemType: Function) {
		this.itemType = itemType;
	}
}
