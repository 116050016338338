import { HeremapsAddress, HeremapsPlaceCategory, HeremapsPoint } from '../common';

export class HeremapsPlaceSearchChargingPoint {
	title: string;
	id: string;
	language: string;
	resultType: string;
	address: HeremapsAddress;
	position: HeremapsPoint;
	access: HeremapsPoint[];
	distance: number;
	categories: HeremapsPlaceCategory[];
	// "contacts": [
	// 	{
	// 		"www": [
	// 			{
	// 				"value": "https://esarj.com/en/"
	// 			}
	// 		]
	// 	}
	// ],
	// "openingHours": [
	// 	{
	// 		"text": [
	// 			"Mon-Sun: 00:00 - 24:00"
	// 		],
	// 		"isOpen": true,
	// 		"structured": [
	// 			{
	// 				"start": "T000000",
	// 				"duration": "PT24H00M",
	// 				"recurrence": "FREQ:DAILY;BYDAY:MO,TU,WE,TH,FR,SA,SU"
	// 			}
	// 		]
	// 	}
	// ]
}
