export enum FuelType {
	GASOLINE = 'GASOLINE',
	DIESEL = 'DIESEL',
	ELECTRIC = 'ELECTRIC',
	NATURALGAS = 'NATURALGAS',
	GPL = 'GPL',
	HYDROGEN = 'HYDROGEN',
	HEV_GASOLINE = 'HEV_GASOLINE',
	PHEV_GASOLINE = 'PHEV_GASOLINE',
	HEV_DIESEL = 'HEV_DIESEL',
	PHEV_DIESEL = 'PHEV_DIESEL',
}
