import numeral from 'numeral';
import { TypeUtils } from './type.util';

export interface FormatOptions {
	prefix?: string;
	suffix?: string;
	seperator?: string;
	defaultValue?: number | string;
	pureDefault?: boolean;
}

export interface NumberFormatOptions extends FormatOptions {
	decimalScale?: number;
}

export interface PriceFormatOptions extends FormatOptions {
	decimalScale?: number;
}

export class NumberUtils {
	public static format = (
		input: Maybe<number | string>,
		format: string,
		{ prefix, suffix, defaultValue = '-', pureDefault, seperator = ' ' }: FormatOptions = {}
	): string => {
		if (TypeUtils.isNumber(input)) {
			return [prefix, numeral(input).format(format).toUpperCase(), suffix].filter(Boolean).join(seperator);
		} else {
			return pureDefault ? `${defaultValue}` : [prefix, `${defaultValue}`, suffix].filter(Boolean).join(seperator);
		}
	};

	public static formatAsPrice = (input: Maybe<number | string>, opts?: PriceFormatOptions, format?: string): string => {
		const decimalScale = opts?.decimalScale ?? 2;
		const fmt = format || `0,0${decimalScale ? `[.]${'0'.repeat(decimalScale)}` : ''}`;

		return NumberUtils.format(input, fmt, { prefix: '€', defaultValue: 0, ...opts });
	};

	public static formatAsNumber = (input: Maybe<number | string>, opts?: NumberFormatOptions): string => {
		const decimalScale = opts?.decimalScale ?? 2;
		const format = `0,0${decimalScale ? `[.]${'0'.repeat(decimalScale)}` : ''}`;
		return NumberUtils.format(input, format, { defaultValue: 0, ...opts });
	};

	public static random = (min: number, max: number): number => {
		const minimum = Math.ceil(min);
		const maximum = Math.floor(max);
		return Math.floor(Math.random() * (maximum - minimum + 1) + minimum); // The maximum is inclusive and the minimum is inclusive
	};
}
