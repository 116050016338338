import { FC, PropsWithChildren, useEffect, useState } from 'react';
import styles from './ImageSlider.module.scss';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Stack, IconButton } from '@mui/material';
import { NextIcon, PreviousIcon } from '@components/icons';
import 'swiper/css';
import 'swiper/css/navigation';
import { StackProps } from '@mui/system';

const getIndex = (ind: number, images: string[]) => Math.max(0, Math.min(images.length - 1, ind));

export interface IImageSliderProps extends StackProps {
	images?: string[];
	index?: number;
	onIndexChange?: (index: number) => void;
	onImageClick?: (index: number) => void;
}

export const ImageSlider: FC<PropsWithChildren<IImageSliderProps>> = ({ images, index = 0, onIndexChange, onImageClick, sx, ...props }) => {
	const [swiper, setSwiper] = useState<SwiperClass>();
	const currentIndex = getIndex(index, images || []);

	useEffect(() => {
		images?.length && index < images.length && swiper?.slideTo(getIndex(index, images || []), 100);
	}, [swiper, index, images]);

	return images?.length ? (
		<Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'centet', ...sx }} {...props}>
			<IconButton onClick={() => onIndexChange?.(getIndex(index - 1, images))} disabled={currentIndex === 0}>
				<PreviousIcon color={currentIndex === 0 ? '#595959' : '#ffffff'} borderColor="#595959" />
			</IconButton>
			<Swiper
				simulateTouch={false}
				onSlideChange={(instance) => onIndexChange?.(instance.activeIndex)}
				onSwiper={setSwiper}
				pagination={{ type: 'progressbar' }}
				navigation={false}
				className={styles.ImageSlider}
			>
				{images?.map((vehicleImage, i) => (
					<SwiperSlide key={vehicleImage}>
						<img
							style={{ cursor: onImageClick ? 'pointer' : 'default' }}
							onClick={() => onImageClick?.(i)}
							src={vehicleImage}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<IconButton onClick={() => onIndexChange?.(getIndex(index + 1, images))} disabled={currentIndex >= images.length - 1}>
				<NextIcon color={currentIndex >= images.length - 1 ? '#595959' : '#ffffff'} borderColor="#595959" />
			</IconButton>
		</Stack>
	) : null;
};
