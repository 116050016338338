import { FC, PropsWithChildren } from 'react';
import { NumberUtils, Tr } from '@utils';
import { Stack, Typography, Box, Slider } from '@mui/material';

const MIN = 0;
const MAX = 100000;

export interface ISelectorKilometerProps {
	value?: number;
	onValueChange?: (val: number) => void;
}

export const SelectorKilometer: FC<PropsWithChildren<ISelectorKilometerProps>> = ({ value = 0, onValueChange }) => {
	return (
		<Stack>
			<Typography variant="caption">
				<Tr.Proposal path="calculation-inputs.selector-kilometer.subtitle" />
			</Typography>
			<Stack mt={1}>
				<Box textAlign={'right'}>
					<Typography variant="subtitle1" color="#4FEADF">
						<Tr.Proposal
							path="calculation-inputs.selector-kilometer.selected-km-yearly"
							options={{ value: NumberUtils.formatAsNumber(value) }}
						/>
					</Typography>
					<Typography variant="caption" color="#9A9A9A">
						<Tr.Proposal
							path="calculation-inputs.selector-kilometer.selected-km-daily"
							options={{ value: NumberUtils.formatAsNumber(Math.round(value / 270)) }}
						/>
					</Typography>
				</Box>
				<Slider
					size="medium"
					min={MIN}
					max={MAX}
					step={100}
					aria-label="Small"
					valueLabelDisplay="auto"
					value={value}
					onChange={(_, val) => onValueChange?.(Array.isArray(val) ? val[0] : val) ?? 0}
				/>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Typography variant="caption" color="#9A9A9A">
						<Tr.Proposal
							path="calculation-inputs.selector-kilometer.selected-km-yearly"
							options={{ value: NumberUtils.formatAsNumber(MIN) }}
						/>
					</Typography>
					<Typography variant="caption" color="#9A9A9A">
						<Tr.Proposal
							path="calculation-inputs.selector-kilometer.selected-km-yearly"
							options={{ value: NumberUtils.formatAsNumber(MAX) }}
						/>
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};
