import { FC, PropsWithChildren } from 'react';
import styles from './ModalVehicleDetail.module.scss';
import { CUSTOM_THEME, Tr } from '@utils';
import { NextIcon, PreviousIcon } from '@components/icons';
import { Typography, Stack, IconButton, Grid } from '@mui/material';
import { IVoltaageModalDialogProps, VoltaageModalDialog, VoltaageModalDialogTitle } from '@components/common';
import { useMobile } from '@hooks/useMobile';

export interface IModalVehicleDetailProps extends IVoltaageModalDialogProps {
	stockTitle?: string;
	stockMessage?: string;
	images?: string[];
	imageIndex: number;
	onPrevious: () => void;
	onNext: () => void;
}

export const ModalVehicleDetail: FC<PropsWithChildren<IModalVehicleDetailProps>> = ({
	stockTitle,
	stockMessage,
	images = [],
	imageIndex,
	onPrevious,
	onNext,
	...props
}) => {
	const isMobile = useMobile();
	const isFirst = imageIndex === 0;
	const isLast = imageIndex >= images.length - 1;
	const getNavIconColor = (disabled: boolean): string => (disabled ? '#C8CEF4' : '#6176F4');
	const getNavIconBorderColor = (disabled: boolean): string => (disabled ? '#EDEEF6' : '#D7DCF5');

	return (
		<VoltaageModalDialog
			{...props}
			containerProps={{
				className: styles.ProposedVehicleDetailModal,
				sx: {
					maxHeight: 1.0,
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'column'
				}
			}}
			title={
				<VoltaageModalDialogTitle>
					<Tr.Proposal path="result.vehicle-detail.modal-title" withInsecureTemplating />
				</VoltaageModalDialogTitle>
			}
		>
			<Stack
				direction="column"
				spacing={6}
				sx={{
					padding: { xs: 3, [CUSTOM_THEME.mobileUntil]: 6 },
					alignItems: 'center',
					justifyContent: 'center',
					maxHeight: 1.0,
					overflow: 'hidden'
				}}
			>
				<Grid container sx={{ maxHeight: 1.0, overflow: 'hidden' }}>
					<Grid
						item
						xs={12}
						{...{ [CUSTOM_THEME.mobileUntil]: 7 }}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: 320,
							overflow: 'hidden',
							maxHeight: { xs: 100, [CUSTOM_THEME.mobileUntil]: 320 }
						}}
					>
						<img className={styles.VehicleImage} src={images[imageIndex]} style={{ height: '100%', width: 'auto' }} />
					</Grid>
					<Grid item xs={12} {...{ [CUSTOM_THEME.mobileUntil]: 5 }} sx={{ height: 1, maxHeight: 1.0, overflow: 'hidden' }}>
						<Stack sx={{ paddingX: { xs: 0, [CUSTOM_THEME.mobileUntil]: 4 }, alignItems: 'start' }}>
							<Stack
								direction="row"
								gap={1}
								sx={{
									paddingY: { xs: 0.5, [CUSTOM_THEME.mobileUntil]: 0 },
									marginX: { xs: 'auto', [CUSTOM_THEME.mobileUntil]: 0 }
								}}
							>
								<IconButton onClick={onPrevious} color="primary" disabled={isFirst}>
									<PreviousIcon color={getNavIconColor(isFirst)} borderColor={getNavIconBorderColor(isFirst)} />
								</IconButton>
								<IconButton onClick={onNext} color="primary" disabled={isLast}>
									<NextIcon color={getNavIconColor(isLast)} borderColor={getNavIconBorderColor(isLast)} />
								</IconButton>
							</Stack>
							<Stack spacing={1} sx={{ paddingTop: { xs: 0, [CUSTOM_THEME.mobileUntil]: 4 } }}>
								{stockTitle && <Typography variant={isMobile ? 'h5' : 'h3'}>{stockTitle}</Typography>}
								{stockMessage && <Typography variant={isMobile ? 'caption' : 'body2'}>{stockMessage}</Typography>}
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</VoltaageModalDialog>
	);
};
