import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { instanceToPlain } from 'class-transformer';
import { concatMap, lastValueFrom, of } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';
import { proposalsSelector, reset, setCalculationInputs, setEngagement, setMoreInfoRequested } from '@store/slices/proposal-slice';
import { setToken } from '@store/slices/common.slice';
import { CUSTOM_THEME, RxUtils, TypeUtils } from '@utils';
import { useService } from '@hooks';
import { DriverHabit } from '@models';
import {
	authService,
	b2cCalculationService,
	B2CEngagement,
	b2cEngagementService,
	b2cServiceService,
	b2cStockService,
	heremapsEVChargePointsService,
	heremapsPlaceSearchService
} from '@services';
import { LayoutResult, LayoutCalculationInputs, ModalEngagementForm, LayoutProposalPage } from '@components/proposal';

const RADIUS_DEFAULT = 25;

export const Proposte: FC = () => {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [engagementModalOpen, setEngagementModalOpen] = useState(false);
	const calculationInputs = useSelector(proposalsSelector('calculationInputs'));
	const engagement = useSelector(proposalsSelector('engagement'));
	const { brand, model, version, yearlyKM, habit, place } = calculationInputs || {};

	const isInputCompleted = () => brand && model && version && yearlyKM && habit && place && placeDetail;
	const onCreateEngagement = ({ name, surname, email, phone, village }: Partial<B2CEngagement> = {}) => {
		RxUtils.promisify(
			b2cEngagementService.engage({
				name,
				surname,
				email,
				phone,
				village,
				address: place?.title,
				latitude: placeDetail?.position.lat,
				longitude: placeDetail?.position.lng
			}),
			(result) => {
				dispatch(setEngagement(instanceToPlain(result) as B2CEngagement));
				dispatch(setMoreInfoRequested(false));
			}
		);
	};

	// VALIDATE AND UPDATE STORE FROM QUERY PARAMS
	const updateStoreFromSearchParams = async () => {
		const queryBrand = searchParams.get('brand') || '';
		const queryModel = searchParams.get('model') || '';
		const queryVersion = searchParams.get('version') || '';
		const queryYearlyKM = TypeUtils.isPositiveNumber(searchParams.get('yearkm')) ? +(searchParams.get('yearkm') || 0) : 0;
		const queryHabit = Object.values(DriverHabit).find((item) => item.toLowerCase() === searchParams.get('habit')?.toLowerCase());

		let validatedBrand = '';
		let validatedModel = '';
		let validatedVersion = '';

		try {
			// VALIDATE BRAND
			const availableBrands = await lastValueFrom(authService.getToken().pipe(concatMap(() => b2cStockService.getAllBrands())));
			if (!availableBrands.some((availableBrand) => availableBrand === queryBrand)) {
				throw new Error(`Brand is not valid ${queryBrand}`);
			}
			validatedBrand = queryBrand;

			// VALIDATE MODEL
			const availableModels = await lastValueFrom(
				authService.getToken().pipe(concatMap(() => b2cStockService.getAllModels(queryBrand)))
			);
			if (!availableModels.some((availableModel) => availableModel === queryModel)) {
				throw new Error(`Model is not valid ${queryModel}`);
			}
			validatedModel = queryModel;

			// VALIDATE VERSION
			const availableVersions = await lastValueFrom(
				authService.getToken().pipe(concatMap(() => b2cStockService.getAllVersions(queryBrand, queryModel)))
			);
			if (!availableVersions.some((availableVersion) => availableVersion === queryVersion)) {
				throw new Error(`Version is not valid ${queryVersion}`);
			}
			validatedVersion = queryVersion;
		} catch (error) {
			console.error('Error occured during validation of query params:', error);
		}

		dispatch(
			setCalculationInputs({
				brand: validatedBrand,
				model: validatedModel,
				version: validatedVersion,
				yearlyKM: queryYearlyKM,
				habit: queryHabit
			})
		);
	};

	// SEARCHED PLACE DETAIL FETCH REQUEST
	const { data: placeDetail } = useService(() => (place ? heremapsPlaceSearchService.getById(place.id) : of(undefined)), [place]);

	// TRIGGER CALCULATION
	const { data: calculation } = useService(() => {
		const engagementId = engagement?.id;
		if (isInputCompleted() && engagementId) {
			return authService.getToken().pipe(
				concatMap(() =>
					b2cCalculationService.calculate({
						engagementId,
						brand,
						model,
						version,
						yearlyKM,
						habit,
						address: place!.title,
						latitude: placeDetail?.position.lat,
						longitude: placeDetail?.position.lng
					})
				)
			);
		} else {
			return of(undefined);
		}
	}, [brand, model, version, yearlyKM, habit, place, placeDetail, engagement]);

	// GET ALL SERVICES
	const { data: services = [] } = useService(() => {
		return isInputCompleted() && engagement?.id
			? authService.getToken().pipe(concatMap(() => b2cServiceService.getAll()))
			: of(undefined);
	}, [brand, model, version, yearlyKM, habit, place, placeDetail, engagement]);

	// CHARGING POINT SEARCH REQUEST
	const { data: chargingStations } = useService(
		() => (placeDetail ? heremapsEVChargePointsService.search(placeDetail.position, RADIUS_DEFAULT * 1000) : of(undefined)),
		[placeDetail]
	);

	useEffect(() => {
		updateStoreFromSearchParams();
	}, [searchParams]);

	useEffect(() => {
		setEngagementModalOpen(isInputCompleted() ? (!engagement ? true : false) : false);
	}, [brand, model, version, yearlyKM, habit, place, placeDetail, engagement]);

	useEffect(() => {
		dispatch(setToken(null));
		dispatch(reset());
	}, []);

	return (
		<>
			<LayoutProposalPage
				side={<LayoutCalculationInputs />}
				main={
					<LayoutResult
						calculation={calculation}
						services={services}
						placeDetail={placeDetail}
						chargingStations={chargingStations?.items || []}
					/>
				}
				sideContainerProps={{
					sx: { display: { xs: isInputCompleted() ? 'none' : 'initial', [CUSTOM_THEME.mobileUntil]: 'initial' } }
				}}
				mainContainerProps={{
					sx: { display: { xs: !isInputCompleted() ? 'none' : undefined, [CUSTOM_THEME.mobileUntil]: 'initial' } }
				}}
			/>
			<ModalEngagementForm open={engagementModalOpen} onValueChange={onCreateEngagement} />
		</>
	);
};
