import './polyfills';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { config } from '@config';
import { App } from './App';
import './index.scss';
import { hotjar } from 'react-hotjar';
import ReactGA4 from 'react-ga4';

hotjar.initialize(Number(config.hotjarId), Number(config.hotjarSv));
ReactGA4.initialize(config.gaTrackingId);

console.log('config:', config);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
