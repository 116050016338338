import { config } from '@config';
import { RxUtils, TypeUtils } from '@utils';
import { concatMap, tap, map } from 'rxjs/operators';
import { HttpParams, httpService } from '@services/http';
import { logger } from '@services/logger';
import { HeremapsPlaceSearchServiceType, HeremapsPoint, HeremapsResponse, HeremapsServiceType } from '../common';
import { HeremapsPlaceSearchChargingPoint } from './heremaps-place-search-charging-point.class';
import { from, of } from 'rxjs';
import { heremapsPlaceSearchService } from '../place-search';

export class HeremapsEVChargePointsService {
	private getUrl = (searchServiceType: HeremapsPlaceSearchServiceType = HeremapsPlaceSearchServiceType.BROWSE, version = 'v1') => {
		return `https://${searchServiceType}.${HeremapsServiceType.SEARCH}.hereapi.com/${version}/${searchServiceType}`;
	};

	private prepareQueryParams(params: Record<string, any>): HttpParams {
		return { ...params, apiKey: config.heremapsApiKey, categories: '700-7600-0322', limit: 100 };
	}

	search(center: HeremapsPoint | string, radiusInMeter = 10000) {
		return from(
			typeof center === 'string' ? heremapsPlaceSearchService.getById(center).pipe(map((result) => result.position)) : of(center)
		).pipe(
			concatMap((point) =>
				httpService.get<HeremapsResponse<HeremapsPlaceSearchChargingPoint>>(this.getUrl(), '', {
					params: this.prepareQueryParams({
						at: [point.lat, point.lng].join(','),
						in: `circle:${[point.lat, point.lng].join(',')};r=${radiusInMeter}`,
					}),
					noAuth: true,
					errorHandler: 'notification',
				})
			),
			tap((result) => logger.debug(`[${this.constructor.name}.${this.search.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) =>
				TypeUtils.convertFromExist(new HeremapsResponse<HeremapsPlaceSearchChargingPoint>(HeremapsPlaceSearchChargingPoint), result)
			)
		);
	}
}

export const heremapsEVChargePointsService = new HeremapsEVChargePointsService();
