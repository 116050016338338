import { FC, PropsWithChildren } from 'react';
import { CUSTOM_THEME, Tr } from '@utils';
import { B2CService } from '@services';
import { Typography, Stack } from '@mui/material';
import { IVoltaageModalDialogProps, VoltaageModalDialog, VoltaageModalDialogTitle } from '@components/common';

export interface IModalServiceDetailProps extends IVoltaageModalDialogProps {
	service?: B2CService;
}

export const ModalServiceDetail: FC<PropsWithChildren<IModalServiceDetailProps>> = ({ service, ...props }) => {
	return (
		<VoltaageModalDialog
			{...props}
			title={
				<VoltaageModalDialogTitle>
					<Tr.Proposal path="tell-me-more" />
				</VoltaageModalDialogTitle>
			}
		>
			<Stack sx={{ padding: { xs: 3, [CUSTOM_THEME.mobileUntil]: 8 }, gap: 5, alignItems: 'center', justifyContent: 'center' }}>
				<Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
					<img style={{ width: '100%', height: 'auto', maxWidth: 600 }} src={service?.logoUrl} alt={service?.name} />
				</Stack>
				<Typography variant="body2" sx={{ textAlign: 'justify', lineHeight: '24px' }}>
					{service?.description}
				</Typography>
			</Stack>
		</VoltaageModalDialog>
	);
};
