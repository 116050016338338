import { config } from '@config';
import { RxUtils, TypeUtils } from '@utils';
import { concatMap, tap, map } from 'rxjs/operators';
import { HttpParams, httpService } from '@services/http';
import { logger } from '@services/logger';
import { HeremapsPlaceSearchServiceType, HeremapsResponse, HeremapsServiceType } from '../common';
import { HeremapsPlaceSearchAutocompleteItem } from './heremaps-place-search-autocomplete-item.class';
import { HeremapsPlace } from './heremaps-place.class';

export class HeremapsPlaceSearchService {
	private getUrl = (searchServiceType: HeremapsPlaceSearchServiceType, version = 'v1') => {
		return `https://${searchServiceType}.${HeremapsServiceType.SEARCH}.hereapi.com/${version}/${searchServiceType}`;
	};

	private prepareQueryParams(params: Record<string, any>): HttpParams {
		return { ...params, apiKey: config.heremapsApiKey };
	}

	autocomplete(searchTerm: string) {
		return httpService
			.get<HeremapsResponse<HeremapsPlaceSearchAutocompleteItem>>(this.getUrl(HeremapsPlaceSearchServiceType.AUTOCOMPLETE), '', {
				params: this.prepareQueryParams({ q: searchTerm }),
				noAuth: true,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.debug(`[${this.constructor.name}.${this.autocomplete.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) =>
					TypeUtils.convertFromExist(
						new HeremapsResponse<HeremapsPlaceSearchAutocompleteItem>(HeremapsPlaceSearchAutocompleteItem),
						result
					)
				)
			);
	}

	getById(id: string) {
		return httpService
			.get<HeremapsPlace>(this.getUrl(HeremapsPlaceSearchServiceType.LOOKUP), '', {
				params: this.prepareQueryParams({ id }),
				noAuth: true,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.debug(`[${this.constructor.name}.${this.getById.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.convert(HeremapsPlace, result))
			);
	}
}

export const heremapsPlaceSearchService = new HeremapsPlaceSearchService();
