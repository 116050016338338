import { Type } from 'class-transformer';
import { Stock } from '@models';

export class B2CCalculationResultItemProposedVehicle {
	cum: number;
	cumWithChargeAtHome: number;
	cumWithChargeAtHomeWithSolar: number;
	price: number;
	priceWithChargeAtHome: number;
	priceWithChargeAtHomewithSolar: number;
	saving: number;
	savingWithChargeAtHome: number;
	savingWithChargeAtHomeWithSolar: number;
	treesSaved: number;
	co2: number;
	fuelCost: number;
	fuelCostChargeAtHome: number;
	fuelCostChargeAtHomeWithSolar: number;
	fuelPriceDiesel: number;
	fuelPriceGasoline: number;
	fuelPriceElectricity: number;
	fuelPriceElectricityAtHome: number;
	fuelPriceElectricityAtHomeWithSolar: number;

	@Type(() => Stock)
	proposed: Partial<Stock>;

	images: string[];
}
