import { IConfig } from './config.interface';

// const isProd = (process.env.NODE_ENV || '').toLowerCase().startsWith('prod');
// const apiUrl = isProd ? 'https://api.dev.voltaage.io' : 'http://localhost:3000';
const apiUrl = process.env.REACT_APP_API_URL || 'MISSING_API_URL';

export const config: IConfig = {
	apiUrl,
	lang: process.env.REACT_APP_LANG || 'it',
	heremapsApiKey: process.env.REACT_APP_HEREMAPS_API_KEY || 'not-defined',
	hotjarId: process.env.REACT_APP_HOTJAR_ID || '0',
	hotjarSv: process.env.REACT_APP_HOTJAR_SCRIPT_VERSION || '0',
	gaTrackingId: process.env.REACT_APP_GA4 || 'not-defined',
};
