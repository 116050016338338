import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/types';
import { ICalculationInputs } from '@models';
import { B2CEngagement } from '@services/b2c-engagement';

export interface IProposalsState {
	calculationInputs?: ICalculationInputs;
	hasWallbox?: boolean;
	hasSolarPanel?: boolean;
	selectedServices?: string[];
	engagement?: B2CEngagement;
	moreInfoRequested?: boolean;
}

const initialState: IProposalsState = {};

export const {
	actions: { reset, setCalculationInputs, setHasWallbox, setHasSolarPanel, setSelectedServices, setEngagement, setMoreInfoRequested },
	reducer,
} = createSlice({
	name: 'proposals',
	initialState,
	reducers: {
		reset: (state) => {
			state.calculationInputs && (state.calculationInputs.place = undefined);
			state.calculationInputs = undefined;
			state.hasWallbox = undefined;
			state.hasSolarPanel = undefined;
			state.selectedServices = undefined;
			state.engagement = undefined;
			state.moreInfoRequested = undefined;
		},
		setCalculationInputs: (state, data: PayloadAction<Partial<IProposalsState['calculationInputs']>>) => {
			!state.calculationInputs && (state.calculationInputs = {});
			data.payload && 'brand' in data.payload && (state.calculationInputs.brand = data.payload?.brand);
			data.payload && 'model' in data.payload && (state.calculationInputs.model = data.payload?.model);
			data.payload && 'version' in data.payload && (state.calculationInputs.version = data.payload?.version);
			data.payload && 'yearlyKM' in data.payload && (state.calculationInputs.yearlyKM = data.payload?.yearlyKM);
			data.payload && 'habit' in data.payload && (state.calculationInputs.habit = data.payload?.habit);
			data.payload && 'place' in data.payload && (state.calculationInputs.place = data.payload?.place);
		},
		setHasWallbox: (state, data: PayloadAction<IProposalsState['hasWallbox']>) => {
			state.hasWallbox = data.payload;
			// !data.payload && (state.hasSolarPanel = false);
		},
		setHasSolarPanel: (state, data: PayloadAction<IProposalsState['hasSolarPanel']>) => {
			state.hasSolarPanel = data.payload;
			// data.payload && (state.hasWallbox = true);
		},
		setSelectedServices: (state, data: PayloadAction<IProposalsState['selectedServices']>) => {
			state.selectedServices = data.payload;
		},
		setEngagement: (state, data: PayloadAction<IProposalsState['engagement']>) => {
			state.engagement = data.payload;
		},
		setMoreInfoRequested: (state, data: PayloadAction<IProposalsState['moreInfoRequested']>) => {
			state.moreInfoRequested = data.payload;
		},
	},
});

export const proposalsSelector =
	<KeyType extends keyof IProposalsState = keyof IProposalsState, ReturnType = RootState['Proposals'][KeyType]>(key: KeyType) =>
	(state: RootState): ReturnType =>
		state.Proposals[key] as unknown as ReturnType;
