import { FC } from 'react';
import { useService } from '@hooks';
import { loadingSpinnerOverlayService } from '@services';
import { Backdrop } from '@mui/material';
import Lottie from 'lottie-react';

import fireworkLoading from '../../../assets/animations/firework-loading.json';

export const LoadingSpinnerOverlay: FC = () => {
	const { data: loadingSpinnerCounter = 0 } = useService(loadingSpinnerOverlayService.getSubject);

	return (
		<Backdrop
			sx={{ color: 'primary.main', backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={loadingSpinnerCounter > 0}
		>
			<Lottie style={{ height: 400 }} animationData={fireworkLoading} loop={true} />
		</Backdrop>
	);
};
