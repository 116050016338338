export enum HeremapsEVConnectorType {
	Unspecified,
	Other,
	Unallowed,
	SmallPaddleInductive,
	LargePaddleInductive,
	NEMA_5_15, // Domestic plug/socket type B
	NEMA_5_20, // Domestic plug/socket type B
	BS_546, // Domestic plug/socket type D (3 pin)
	CEE_7_5, // Domestic plug/socket type E (CEE 7/5)
	CEE_7_4, // Domestic plug/socket type F (CEE 7/4 (Schuko))
	CEE_7_7, // Domestic plug/socket type E+F (CEE 7/7)
	BS_1363_IS_401_IS_411_MS_58, // Domestic plug/socket type G (BS 1363, IS 401 & 411, MS 58)
	SI_32, // Domestic plug/socket type H (SI 32)
	AS_NZS_3112, // Domestic plug/socket type I (AS/NZS 3112)
	CPCS_CCC, // Domestic plug/socket type I (CPCS-CCC)
	IRAM_2073, // Domestic plug/socket type I (IRAM 2073)
	T13, // Domestic plug/socket type J (SEV 1011) (T13)
	T15, // Domestic plug/socket type J (SEV 1011) (T15)
	T23, // Domestic plug/socket type J (SEV 1011) (T23)
	T25, // Domestic plug/socket type J (SEV 1011) (T25)
	Section_107_2_D1, // Domestic plug/socket type K (Section 107-2-D1)
	Thailand_TIS_166_2549, // Domestic plug/socket type K (Thailand TIS 166 - 2549)
	CEI_23_16_VII, // Domestic plug/socket type L (CEI 23-16/VII)
	South_African_15, //Domestic plug/socket type M (South African 15 A/250 V)
	IEC_60906_1, // Domestic plug/socket type IEC 60906-1 (3 pin)
	AVCON_Connector,
	TeslaConnector_HighPowerWall,
	TeslaConnector_UniversalMobile,
	TeslaConnector_SpareMobile,
	JEVS_G_105, // CHAdeMO
	IEC_62196_2_Type1, // IEC 62196-2 type 1 (SAE J1772)
	IEC_62196_2_Type2, // IEC 62196-2 type 2 (Mennekes)
	IEC_62196_2_Type3, // IEC 62196-2 type 3 (SCAME)
	IEC_62196_2_Type1_Combo, // IEC 62196-2 type 1 combo (SAE J1772)
	IEC_62196_2_Type2_Combo, // IEC 62196-2 type 2 combo (Mennekes)
	Industrial_P_N_E, // 60309 : industrial P + N + E (AC)
	Industrial_3P_N_E, // 60309 : industrial 3P + E + N (AC)
	Industrial_2P_E, // 60309 : industrial 2P + E (DC)
	Industrial_P_N_E_CEEPlus, //  60309 : industrial P + N + E (AC) (CEEPlus)
	Industrial_3P_N_E_CEEPlus, // 60309 : industrial 3P + N + E (AC) (CEEPlus)
	BetterPlacePlugSocket,
	MarechalPlugSocket,
	SEV_1011, // Domestic plug/socket type J (SEV 1011) (T13, T23)
	TeslaConnector,
	IEC_61851_1,
	IEC_62196_2_Type2_2, // (SAE J1772)
	Industrial_2P_E_2, // 60309 : industrial 2P + E (DC)
	IType_AS_NZ_3112, // (Australian 15A/240V)
	NEMA_1_15, // Domestic plug/socket type A (NEMA 1-15, 2 pins)
	CEE_7_17, // Domestic plug/socket type C (CEE 7/17, 2 pins)
	IEC_62196_2_Type3a, // IEC 62196-2 type 3a (SCAME)
	NEMA_14_50, // Domestic plug/socket type B (NEMA 14-50)
	GBT_Chinese_AC_Connector,
	GBT_Chinese_DC_Connector,
	IEC_62196_2_Type2_3, // IEC 62196-2 type 2
}
