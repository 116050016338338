import { FC, PropsWithChildren } from 'react';
import { CUSTOM_THEME } from '@utils';
import { FuelType } from '@models';
import { Stack, Box, IconButton, Typography, Card, CardProps } from '@mui/material';
import { ChangeIcon } from '@components/icons';
import mercedesIcon from '../../../assets/cars/mercedes-icon.svg';
import { VehicleGreenStatusLabel } from '../VehicleGreenStatusLabel';

export interface ICardVehicleSummaryProps extends CardProps {
	brand?: string;
	model?: string;
	version?: string;
	fuelType?: FuelType;
	onReset?: () => void;
}

export const CardVehicleSummary: FC<PropsWithChildren<ICardVehicleSummaryProps>> = ({
	brand,
	model,
	version,
	fuelType,
	onReset,
	sx,
	...props
}) => {
	return (
		<Card
			variant="outlined"
			sx={{
				position: 'relative',
				borderRadius: 22.5,
				overflow: 'visible',
				alignItems: 'center',
				padding: { xs: 1, [CUSTOM_THEME.mobileUntil]: 0 },
				border: { xs: undefined, [CUSTOM_THEME.mobileUntil]: 'none' },
				backgroundColor: { xs: undefined, [CUSTOM_THEME.mobileUntil]: 'transparent' },
				...sx
			}}
			{...props}
		>
			<VehicleGreenStatusLabel
				fuelType={fuelType}
				sx={{
					position: 'absolute',
					top: -14,
					left: 80,
					display: { xs: 'flex', [CUSTOM_THEME.mobileUntil]: 'none' }
				}}
			/>
			<Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
				<Stack direction="row" spacing={2} alignItems="center" sx={{ flexGrow: 1, maxWidth: 1.0, overflow: 'hidden' }}>
					<Box
						sx={{
							minWidth: { xs: 64, [CUSTOM_THEME.mobileUntil]: 54 },
							maxWidth: { xs: 64, [CUSTOM_THEME.mobileUntil]: 54 },
							minHeight: { xs: 64, [CUSTOM_THEME.mobileUntil]: 54 },
							maxHeight: { xs: 64, [CUSTOM_THEME.mobileUntil]: 54 },
							backgroundColor: 'transparent',
							borderWidth: 2,
							borderStyle: 'solid',
							borderColor: { xs: '#4FEADF', [CUSTOM_THEME.mobileUntil]: '#6435F4' },
							borderRadius: '50%',
							padding: 0.25,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'relative'
						}}
					>
						<Box
							sx={{
								backgroundColor: 'common.white',
								minWidth: { xs: 54, [CUSTOM_THEME.mobileUntil]: 44 },
								maxWidth: { xs: 54, [CUSTOM_THEME.mobileUntil]: 44 },
								minHeight: { xs: 54, [CUSTOM_THEME.mobileUntil]: 44 },
								maxHeight: { xs: 54, [CUSTOM_THEME.mobileUntil]: 44 },
								borderRadius: '50%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: 1
							}}
						>
							<Box
								component="img"
								src={mercedesIcon}
								sx={{
									borderRadius: '50%',
									width: 1.0,
									height: 'auto'
								}}
							/>
						</Box>

						<IconButton
							onClick={onReset}
							sx={{
								display: { xs: 'none', [CUSTOM_THEME.mobileUntil]: 'inline-flex' },
								position: 'absolute',
								bottom: '0px',
								right: '-5px',
								p: 0,
								transform: 'scale(1.2)'
							}}
						>
							<ChangeIcon />
						</IconButton>
					</Box>

					<Stack sx={{ overflow: 'hidden' }} spacing={0.5}>
						<Typography variant="body1" sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
							{brand}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								overflow: 'hidden',
								whiteSpace: { xs: 'nowrap', [CUSTOM_THEME.mobileUntil]: 'normal' },
								textOverflow: 'ellipsis'
							}}
						>
							{(model && version?.includes(model) ? [version] : [model, version]).filter(Boolean).join(' ')}
						</Typography>
					</Stack>
				</Stack>

				<IconButton
					onClick={onReset}
					sx={{
						display: { xs: 'flex', [CUSTOM_THEME.mobileUntil]: 'none' },
						width: 56,
						height: 56,
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<ChangeIcon sx={{ width: 32, height: 32 }} />
				</IconButton>
				<VehicleGreenStatusLabel fuelType={fuelType} sx={{ display: { xs: 'none', [CUSTOM_THEME.mobileUntil]: 'flex' } }} />
			</Stack>
		</Card>
	);
};
