import { HeremapsAddress, HeremapsSearchHighlight } from '../common';

export class HeremapsPlaceSearchAutocompleteItem {
	title: string;
	id: string;
	language: string;
	resultType: string;
	address: HeremapsAddress;
	highlights: {
		title: HeremapsSearchHighlight[];
		address: {
			label: HeremapsSearchHighlight[];
			street: HeremapsSearchHighlight[];
		};
	};
}
