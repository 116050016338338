import { config } from '@config';
import { of } from 'rxjs';
import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';
import { httpService } from '@services/http';
import { logger } from '@services/logger';
import { B2CService } from './b2c-service.class';

// TODO: remove when the API is ready
import mercerdes_1 from '../../assets/cars/carousel_new/mercedes_1.jpeg';
import mercerdes_2 from '../../assets/cars/carousel_new/mercedes_2.png';
import mercerdes_3 from '../../assets/cars/carousel_new/mercedes_3.png';
import mercerdes_4 from '../../assets/cars/carousel_new/mercedes_4.png';

const MOCKED = false;
const MOCK_SERVICES = TypeUtils.convert(B2CService, [
	{
		id: '3e551ca6-441f-4c52-8540-ac394b3fc850',
		createdAt: '2022-10-12T19:51:38.034Z',
		updatedAt: '2022-12-14T20:31:10.823Z',
		name: '+ €1,500.00',
		description: 'Long description',
		summary: null,
		supplier: 'Supplier',
		serviceType: 'Service Type',
		organizationId: null,
		price: 123,
		available: null,
		paymentRecurrence: 'RECURRING',
		paymentType: 'PER_DRIVER',
		logoUrl: mercerdes_1,
	},
	{
		id: 'a2a0b1c6-5071-45c5-95cc-a11158201427',
		createdAt: '2022-10-12T19:52:05.653Z',
		updatedAt: '2022-12-14T20:33:54.107Z',
		name: '1 free year',
		description: 'Long description',
		summary: null,
		supplier: 'Supplier',
		serviceType: 'Service Type',
		organizationId: null,
		price: 123,
		available: null,
		paymentRecurrence: 'ONE_TIME',
		paymentType: 'PER_DRIVER',
		logoUrl: mercerdes_2,
	},
	{
		id: 'e4be0b06-f11e-4a08-a515-2c9a9aed66c3',
		createdAt: '2022-10-12T19:52:39.082Z',
		updatedAt: '2022-12-14T20:34:04.056Z',
		name: '3 free months',
		description: 'Long description',
		summary: null,
		supplier: 'Supplier',
		serviceType: 'Service Type',
		organizationId: null,
		price: 123,
		available: null,
		paymentRecurrence: 'ONE_TIME',
		paymentType: 'PER_DRIVER',
		logoUrl: mercerdes_3,
	},
	{
		id: '3dda581b-003f-4af7-8f8c-e1060bed46a2',
		createdAt: '2022-10-12T19:53:05.443Z',
		updatedAt: '2022-12-14T20:34:12.470Z',
		name: 'Free car delivery',
		description: 'Long description',
		summary: null,
		supplier: 'Supplier',
		serviceType: 'Service Type',
		organizationId: null,
		price: 123,
		available: null,
		paymentRecurrence: 'ONE_TIME',
		paymentType: 'PER_VEHICLE',
		logoUrl: mercerdes_4,
	},
]);

export class B2CServiceService {
	private getUrl = () => `${config.apiUrl}/b2c/trivellato`;

	getAll() {
		if (MOCKED) {
			return of([...MOCK_SERVICES]);
		}

		return httpService.get<B2CService[]>(this.getUrl(), 'services', { errorHandler: 'notification' }).pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.getAll.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.convert(B2CService, result))
		);
	}
}

export const b2cServiceService = new B2CServiceService();
