import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { of } from 'rxjs';
import { useService } from '@hooks';
import { PlatformContext, Tr } from '@utils';
import { heremapsEVChargePointsService, HeremapsPlace, HeremapsPlaceSearchChargingPoint } from '@services';
import { Box, Slider, sliderClasses, Stack, Typography } from '@mui/material';
import { IVoltaageModalDialogProps, VoltaageModalDialog } from '@components/common';
import ChargingStationIcon from '@assets/icons/charging-station-pin.svg';

const RADIUS_MIN = 1;
const RADIUS_DEFAULT = 25;
const RADIUS_MAX = 25;
const MAP_PADDING_X = 20;
const MAP_PADDING_Y = 200;

export interface IModalChargingStationsMapProps extends IVoltaageModalDialogProps {
	placeDetail?: HeremapsPlace;
}

export const ModalChargingStationsMap: FC<PropsWithChildren<IModalChargingStationsMapProps>> = ({ open, placeDetail, ...props }) => {
	const platform = useContext(PlatformContext);
	const isEverythingFine = platform && typeof window.H !== 'undefined';
	if (!isEverythingFine) {
		return null;
	}

	const [radiusValue, setRadiusValue] = useState(RADIUS_DEFAULT);
	// const [activeChargingStation, setActiveChargingStation] = useState<HeremapsPlaceSearchChargingPoint>();

	// CHARGING POINT SEARCH REQUEST
	const { data: chargingStations } = useService(
		() => (placeDetail ? heremapsEVChargePointsService.search(placeDetail.position, radiusValue * 1000) : of(undefined)),
		[placeDetail, radiusValue]
	);

	// ELEMENTS
	const mapContainer = useRef<HTMLElement>();

	// INSTANCES
	const mapInstance = useRef<H.Map>();
	const mapDefaultLayers = useRef<H.service.DefaultLayers>(platform.createDefaultLayers());
	const mapBehavior = useRef<H.mapevents.Behavior>();
	const mapUI = useRef<H.ui.UI>();
	const chargingPointsGroup = useRef(new H.map.Group());
	const chargingPointPopup = useRef(new H.ui.InfoBubble(placeDetail?.position || { lat: 0, lng: 0 }));
	const chargingPointsGroupCircle = useRef(
		new H.map.Circle(placeDetail?.position || { lat: 0, lng: 0 }, radiusValue * 1000, {
			visibility: false,
			style: {
				strokeColor: '#72DC31',
				lineWidth: 4,
				fillColor: 'rgba(138, 234, 79, 0.4)'
			}
		})
	);

	const onWindowSizeChanged = (): void => {
		mapInstance.current?.getViewPort().resize();
	};

	const onMarkerClicked = (evt: Event): void => {
		const marker = evt.target as unknown as H.map.Marker;
		const station = marker.getData() as HeremapsPlaceSearchChargingPoint;
		chargingPointPopup.current?.close();

		if (station && chargingPointPopup.current) {
			chargingPointPopup.current.setPosition(station.position);
			chargingPointPopup.current.setContent(station.title);
			chargingPointPopup.current.open();
		}
	};

	const destroyMap = () => {
		window.removeEventListener('resize', onWindowSizeChanged);
		mapInstance.current?.dispose();
		mapInstance.current = undefined;
		mapBehavior.current = undefined;
		chargingPointsGroup.current.removeAll();
		chargingPointsGroup.current?.removeEventListener('tap', onMarkerClicked, false);
	};

	const refreshChargingPoints = () => {
		chargingPointsGroup.current.removeAll();
		for (const station of chargingStations?.items || []) {
			if (station.position) {
				chargingPointsGroup.current?.addObject(
					new H.map.Marker(station.position, { icon: new H.map.Icon(ChargingStationIcon), data: station })
				);
			}
		}

		mapInstance.current?.getViewModel().setLookAtData(
			{
				bounds: chargingPointsGroupCircle.current.getBoundingBox()
			},
			true
		);
	};

	const initMap = (containerElement: HTMLElement, opts?: H.Map.Options): void => {
		mapInstance.current = new H.Map(containerElement, mapDefaultLayers.current.vector.normal.map, opts);
		mapBehavior.current = new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));

		mapUI.current = H.ui.UI.createDefault(mapInstance.current, mapDefaultLayers.current);
		mapUI.current.getControl('zoom').setVisibility(false);
		mapUI.current.getControl('mapsettings').setVisibility(false);
		mapUI.current.getControl('scalebar').setVisibility(false);
		mapUI.current?.addBubble(chargingPointPopup.current);
		chargingPointPopup.current?.close();
		mapInstance.current.addObject(chargingPointsGroup.current);
		mapInstance.current.addObject(chargingPointsGroupCircle.current);

		window.addEventListener('resize', onWindowSizeChanged);
		chargingPointsGroup.current?.addEventListener('tap', onMarkerClicked, false);

		refreshChargingPoints();
	};

	const onMapContainerRefChanged = (el: HTMLElement) => {
		if (!mapContainer.current && el) {
			mapContainer.current = el;
			mapInstance.current && destroyMap();
			if (!mapInstance.current) {
				initMap(el, {
					center: placeDetail?.position || { lat: 0, lng: 0 },
					zoom: 14,
					pixelRatio: window.devicePixelRatio || 1,
					padding: { top: MAP_PADDING_Y, bottom: MAP_PADDING_Y, left: MAP_PADDING_X, right: MAP_PADDING_X }
				});
			}
		}
	};

	useEffect(refreshChargingPoints, [chargingStations]);

	useEffect(() => {
		chargingPointsGroupCircle.current.setCenter(placeDetail?.position || { lat: 0, lng: 0 });
		chargingPointsGroupCircle.current.setRadius(radiusValue * 1000);
		chargingPointsGroupCircle.current.setVisibility(true);
	}, [radiusValue, placeDetail?.position]);

	useEffect(() => {
		if (!open) {
			destroyMap();
			mapContainer.current = undefined;
		}
	}, [open]);

	return (
		<VoltaageModalDialog
			{...props}
			open={open}
			fullScreen
			maxWidth="xl"
			containerProps={{ sx: { height: 1.0, display: 'flex', flexDirection: 'column' } }}
			closeButtonProps={{
				sx: { backgroundColor: 'common.white', width: 40, height: 40, '&:hover': { backgroundColor: 'common.white', boxShadow: 8 } }
			}}
		>
			<Stack direction="column" sx={{ flexGrow: 1, width: 1.0, position: 'relative' }}>
				<Box
					sx={{
						position: 'absolute',
						display: 'flex',
						width: 1.0,
						maxWidth: 375,
						bottom: 40,
						left: '50%',
						transform: 'translateX(-50%)',
						borderRadius: 2.5,
						backgroundColor: 'common.white',
						paddingX: 2,
						paddingY: 2,
						boxShadow: 3,
						zIndex: 999999999
					}}
				>
					<Stack direction="row" alignItems="center" spacing={1.5} sx={{ width: 1.0, color: 'background.default' }}>
						<Typography variant="caption" sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap', fontWeight: 600 }}>
							<Tr.Proposal path="result.charging-stations.map.range" />
						</Typography>
						<Slider
							size="medium"
							min={RADIUS_MIN}
							max={RADIUS_MAX}
							step={1}
							valueLabelDisplay="auto"
							value={radiusValue}
							onChange={(_, val) => setRadiusValue(Array.isArray(val) ? val[0] : val)}
							onChangeCommitted={(_, val) => {
								const radius = Array.isArray(val) ? val[0] : val;
								setRadiusValue(radius);
								ReactGA4.event({ category: 'Charging Station', action: 'Radius Changed', label: `${radius} km` });
							}}
							sx={{
								[`& .${sliderClasses.rail}`]: {
									backgroundColor: 'grey.200',
									opacity: 0.8
								}
							}}
						/>
						<Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
							<Tr.Proposal
								path="result.charging-stations.map.selected-radius"
								options={{ value: radiusValue }}
								withInsecureTemplating
							/>
						</Typography>
					</Stack>
				</Box>
				<Box
					ref={onMapContainerRefChanged}
					sx={{
						display: 'block',
						flexGrow: 1,
						width: '100%',
						maxWidth: '100%',
						height: '100%',
						maxHeight: '100%'
					}}
				/>
			</Stack>
		</VoltaageModalDialog>
	);
};
