import { DriverHabit } from './driver-habit.enum';
import { FuelType } from './fuel-type.enum';
import { GearType } from './gear-type.enum';

export class CarModel {
	id: number;
	codice_eurotax: number;
	id_dbmotori: string;
	brand: string;
	model: string;
	version: string;
	price: number;
	body: string;
	carSegment: string;
	motorFuelType: FuelType;
	euroMotor: string;
	wheelDrive: string;
	wltpNedc: string;
	co2EmissionUrban: number;
	co2EmissionHighway: number;
	co2EmissionCombined: number;
	fuelConsumptionUrban: number;
	fuelConsumptionHighway: number;
	fuelConsumptionCombined: number;
	wltpConsumptionUrbanCold: number;
	wltpConsumptionHighwayCold: number;
	wltpConsumptionCombinedCold: number;
	wltpConsumptionUrbanMild: number;
	wltpConsumptionHighwayMild: number;
	wltpConsumptionCombinedMild: number;
	realConsumptionUrbanCold: number;
	realConsumptionHighwayCold: number;
	realConsumptionCombinedCold: number;
	realConsumptionUrbanMild: number;
	realConsumptionHighwayMild: number;
	realConsumptionCombinedMild: number;
	wltpRangeUrbanCold: number;
	wltpRangeHighwayCold: number;
	wltpRangeCombinedCold: number;
	wltpRangeUrbanMild: number;
	wltpRangeHighwayMild: number;
	wltpRangeCombinedMild: number;
	realRangeUrbanCold: number;
	realRangeHighwayCold: number;
	realRangeCombinedCold: number;
	realRangeUrbanMild: number;
	realRangeHighwayMild: number;
	realRangeCombinedMild: number;
	electricalMotorEfficiency: number;
	batteryCapacity: number;
	batteryUsable: number;
	batterySlowPortType: string;
	batterySlowChargePower: number;
	batterySlowChargeSpeed: number;
	batteryFastPortType: string;
	batteryFastChargePower: number;
	batteryFastChargeSpeed: number;
	leasingPrice: number;
	seats: number;
	doors: number;
	maxSpeed: number;
	gearType: GearType;

	getConsumption(habit: DriverHabit) {
		const isEV = this.motorFuelType === FuelType.ELECTRIC;
		const fallbackEVConsumption =
			this.wltpConsumptionCombinedMild ||
			this.wltpConsumptionCombinedCold ||
			this.wltpConsumptionUrbanMild ||
			this.wltpConsumptionUrbanCold ||
			this.wltpConsumptionHighwayMild ||
			this.wltpConsumptionHighwayCold;
		const fallbackConsumption = this.fuelConsumptionCombined || this.fuelConsumptionUrban || this.fuelConsumptionHighway;

		const consumptionMap: Record<DriverHabit, number> = {
			[DriverHabit.URBAN]: isEV ? this.wltpConsumptionUrbanMild : this.fuelConsumptionUrban,
			[DriverHabit.HIGHWAY]: isEV ? this.wltpConsumptionHighwayMild : this.fuelConsumptionHighway,
			[DriverHabit.COMBINED]: isEV ? this.wltpConsumptionCombinedMild : this.fuelConsumptionCombined,
		};

		return (habit ? consumptionMap[habit] : undefined) || (isEV ? fallbackEVConsumption : fallbackConsumption);
	}
}
