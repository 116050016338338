import { plainToInstance } from 'class-transformer';
import { config } from '@config';
import { of } from 'rxjs';
import { concatMap, tap, map } from 'rxjs/operators';
import { CarModel, FuelType, GearType, Stock } from '@models';
import { RxUtils, TypeUtils } from '@utils';
import { httpService } from '@services/http';
import { logger } from '@services/logger';
import { B2CCalculationResult } from './b2c-calculation-result.class';
import { B2CCalculationRequest } from './b2c-calculation-request.dto';
import { B2CCalculationVehicleType } from './b2c-calculation-vehicle-type.enum';

// TODO: remove when the API is ready
import mercedesEQA from '../../assets/cars/Mercedes-EQA.svg';
import mercerdes_1 from '../../assets/cars/carousel_new/mercedes_1.jpeg';
import mercerdes_2 from '../../assets/cars/carousel_new/mercedes_2.png';
import mercerdes_3 from '../../assets/cars/carousel_new/mercedes_3.png';
import mercerdes_4 from '../../assets/cars/carousel_new/mercedes_4.png';
import mercerdes_5 from '../../assets/cars/carousel_new/mercedes_5.png';
import mercerdes_6 from '../../assets/cars/carousel_new/mercedes_6.png';

const MOCKED = false;
const MOCK_CAR_MODEL = plainToInstance<CarModel, Partial<CarModel>>(CarModel, {
	id: 20111,
	codice_eurotax: undefined,
	id_dbmotori: undefined,
	brand: 'Mercedes',
	model: 'EQA',
	version: 'EQA 350 4MATIC',
	price: 58610,
	body: 'SUV-Crossover-4x4',
	carSegment: 'C SUV (Compact)',
	motorFuelType: FuelType.ELECTRIC,
	euroMotor: undefined,
	wheelDrive: 'All',
	wltpNedc: undefined,
	co2EmissionUrban: 0,
	co2EmissionHighway: 0,
	co2EmissionCombined: undefined,
	fuelConsumptionUrban: undefined,
	fuelConsumptionHighway: undefined,
	fuelConsumptionCombined: undefined,
	wltpConsumptionUrbanCold: 19,
	wltpConsumptionHighwayCold: 19,
	wltpConsumptionCombinedCold: 19,
	wltpConsumptionUrbanMild: 19,
	wltpConsumptionHighwayMild: 19,
	wltpConsumptionCombinedMild: 19,
	realConsumptionUrbanCold: 19,
	realConsumptionHighwayCold: 19,
	realConsumptionCombinedCold: 19,
	realConsumptionUrbanMild: 19,
	realConsumptionHighwayMild: 19,
	realConsumptionCombinedMild: 19,
	wltpRangeUrbanCold: undefined,
	wltpRangeHighwayCold: undefined,
	wltpRangeCombinedCold: undefined,
	wltpRangeUrbanMild: undefined,
	wltpRangeHighwayMild: undefined,
	wltpRangeCombinedMild: 350,
	realRangeUrbanCold: undefined,
	realRangeHighwayCold: undefined,
	realRangeCombinedCold: undefined,
	realRangeUrbanMild: undefined,
	realRangeHighwayMild: undefined,
	realRangeCombinedMild: 69.7,
	electricalMotorEfficiency: undefined,
	batteryCapacity: 69.7,
	batteryUsable: 69.7,
	batterySlowPortType: undefined,
	batterySlowChargePower: 11,
	batterySlowChargeSpeed: undefined,
	batteryFastPortType: undefined,
	batteryFastChargePower: 11,
	batteryFastChargeSpeed: undefined,
	leasingPrice: undefined,
	doors: 4,
	seats: 5,
	gearType: GearType.AUTOMATIC,
	maxSpeed: 210,
});

const MOCK_STOCK = plainToInstance<Stock, Partial<Stock>>(Stock, {
	id: '',
	carModel: MOCK_CAR_MODEL,
	carModelId: MOCK_CAR_MODEL.id,
	title: 'Mocked stock title',
	message:
		'Mocked stock message - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras convallis tellus a sapien ultrices euismod. Integer viverra nisl hendrerit urna dignissim, in tempor quam interdum. Nunc euismod justo in mauris cursus tincidunt. Nulla lectus sapien, dictum at felis ac, dictum scelerisque risus. Donec id scelerisque lacus. Maecenas sed erat molestie libero lobortis euismod et eu turpis.',
});

const MOCK_RESPONSE: B2CCalculationResult = {
	engagementId: 'engagement-id',
	[B2CCalculationVehicleType.EV]: {
		cum: 456.1,
		cumWithChargeAtHome: 456.2,
		cumWithChargeAtHomeWithSolar: 456.3,
		price: 789.1,
		priceWithChargeAtHome: 789.2,
		priceWithChargeAtHomewithSolar: 789.3,
		saving: 1234,
		savingWithChargeAtHome: 1111,
		savingWithChargeAtHomeWithSolar: 999,
		treesSaved: 12,
		fuelCost: 1.01,
		fuelCostChargeAtHome: 1.02,
		fuelCostChargeAtHomeWithSolar: 1.03,
		fuelPriceDiesel: 1.864,
		fuelPriceGasoline: 1.802,
		fuelPriceElectricity: 0.39,
		fuelPriceElectricityAtHome: 0.45,
		fuelPriceElectricityAtHomeWithSolar: 0.28,
		co2: 123,
		proposed: MOCK_STOCK,
		images: [mercedesEQA, mercerdes_1, mercerdes_2, mercerdes_3],
	},
	[B2CCalculationVehicleType.PHEV]: {
		cum: 123.1,
		cumWithChargeAtHome: 123.2,
		cumWithChargeAtHomeWithSolar: 123.3,
		price: 789.1,
		priceWithChargeAtHome: 789.2,
		priceWithChargeAtHomewithSolar: 789.3,
		saving: 1234,
		savingWithChargeAtHome: 1111,
		savingWithChargeAtHomeWithSolar: 999,
		treesSaved: 12,
		fuelCost: 1.01,
		fuelCostChargeAtHome: 1.02,
		fuelCostChargeAtHomeWithSolar: 1.03,
		fuelPriceDiesel: 1.864,
		fuelPriceGasoline: 1.802,
		fuelPriceElectricity: 0.39,
		fuelPriceElectricityAtHome: 0.45,
		fuelPriceElectricityAtHomeWithSolar: 0.28,
		co2: 123,
		proposed: MOCK_STOCK,
		images: [mercerdes_4, mercerdes_5, mercerdes_6],
	},
	ice: {
		cum: 439.37208333333336,
		price: 1156.03875,
		co2: 5.0935,
		fuelCost: 3572.465,
		fuelPriceDiesel: 1.864,
		fuelPriceGasoline: 1.802,
		fuelPriceElectricity: 0.39,
		model: MOCK_CAR_MODEL,
		images: [],
	},
};

export class B2CCalculationService {
	private getUrl = () => `${config.apiUrl}/b2c/trivellato`;

	calculate(request: Partial<B2CCalculationRequest>) {
		return (
			MOCKED
				? of(MOCK_RESPONSE)
				: httpService.post<B2CCalculationResult>(this.getUrl(), 'calculate-b2c', {
						body: request,
						errorHandler: 'notification',
				  })
		).pipe(
			tap((result) => logger.debug(`[${this.constructor.name}.${this.calculate.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.convert(B2CCalculationResult, result))
		);
	}
}

export const b2cCalculationService = new B2CCalculationService();
