import { FC, PropsWithChildren } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as IconComponent } from '../../assets/icons/Close-Icon.svg';

export const CloseIcon: FC<PropsWithChildren<SvgIconProps>> = ({ sx, ...rest }) => {
	const { width = 17, height = 17 } = rest;
	return (
		<SvgIcon
			component={IconComponent}
			sx={{ width: { width }, height: { height }, ...sx }}
			viewBox={`0 0 ${width} ${height}`}
			{...rest}
		/>
	);
};
