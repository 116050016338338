import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA4 from 'react-ga4';
import { proposalsSelector, reset, setCalculationInputs, setHasWallbox } from '@store/slices/proposal-slice';
import { useMobile } from '@hooks';
import { CUSTOM_THEME, Tr } from '@utils';
import { ICalculationInputs } from '@models';
import {
	Box,
	Button,
	Stack,
	Step,
	stepClasses,
	StepConnector,
	stepConnectorClasses,
	StepContent,
	stepContentClasses,
	stepIconClasses,
	StepIconProps,
	StepLabel,
	stepLabelClasses,
	Stepper,
	StepperProps,
	Typography
} from '@mui/material';
import { SelectorDrivingHabit } from '../SelectorDrivingHabit';
import { SelectorKilometer } from '../SelectorKilometer';
import { SelectorLocation } from '../SelectorLocation';
import { SelectorVehicle } from '../SelectorVehicle';
import { LayoutNavbar } from '../LayoutNavbar';

const MobileStepperConnectorSx: StepperProps['sx'] = {
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}:first-child`]: {
			backgroundImage: 'linear-gradient(to right, #2fb2dd, #528cee)'
		}
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage: 'linear-gradient(to right, #2fdddc, #2fdddc)'
		}
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1
	}
};

const MobileStepperSx: StepperProps['sx'] = {
	[`& .${stepClasses.horizontal}`]: {
		padding: '0px'
	},
	[`& .${stepLabelClasses.iconContainer}`]: {
		padding: '0px'
	},
	[`& .${stepIconClasses.root}`]: {
		color: 'white',
		fill: 'white',
		width: '8px',
		height: '8px'
	},
	[`& .${stepConnectorClasses.lineHorizontal}`]: {
		borderTop: '2px solid',
		borderStyle: 'solid'
	}
};

const DesktopStepperSx: StepperProps['sx'] = {
	height: 1.0,
	[`& .${stepClasses.root}`]: {
		flexGrow: 1,
		flexBasis: '25%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative'
	},
	[`& .${stepLabelClasses.root}`]: {
		gap: 1,
		position: 'absolute',
		top: 0,
		transform: 'translateY(-50%)'
	},
	[`& .${stepContentClasses.root}`]: {
		flexGrow: 1,
		borderLeft: '4px solid',
		borderImage: 'linear-gradient(to bottom, #2fdddc, #2fdddc)',
		borderImageSlice: '1',
		marginLeft: 0.5,
		paddingY: 1
	},

	'& .MuiStep-vertical:last-child .MuiStepContent-root': {
		border: 0
	},
	'& .MuiCollapse-wrapperInner': {
		marginBlockStart: '1.5rem',
		marginLeft: '9px'
	},
	'& .MuiStepIcon-root.Mui-completed, .MuiStepIcon-root.Mui-active, .MuiStepIcon-text': {
		color: 'white',
		fill: 'white'
	},
	'& .MuiStepLabel-root ': {}
};

const StepIcon: FC<PropsWithChildren<StepIconProps>> = ({ completed, active }) => {
	return (
		<Box
			sx={{
				width: { xs: 10, [CUSTOM_THEME.mobileUntil]: 12 },
				height: { xs: 10, [CUSTOM_THEME.mobileUntil]: 12 },
				backgroundColor: completed || active ? 'common.white' : 'grey.200',
				padding: 0.25,
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Box
				sx={{
					borderRadius: '50%',
					width: 1.0,
					height: 1.0,
					backgroundColor: active ? 'primary.main' : 'transparent'
				}}
			/>
		</Box>
	);
};

interface IInputGaEvent {
	action: string;
	label: string;
}

interface IStepDefinition {
	title: ReactNode;
	content: ReactNode;
}

export interface ILayoutCalculationInputsProps {}

export const LayoutCalculationInputs: FC<PropsWithChildren<ILayoutCalculationInputsProps>> = () => {
	const dispatch = useDispatch();
	const isMobile = useMobile();
	const calculationInputs = useSelector(proposalsSelector('calculationInputs'));
	const hasWallbox = useSelector(proposalsSelector('hasWallbox'));
	const [userInputBuffer, setUserInputBuffer] = useState(calculationInputs);
	const [activeStep, setActiveStep] = useState(0);

	const onNext = () => dispatch(setCalculationInputs(userInputBuffer));
	const partialUpdate = (partial?: Partial<ICalculationInputs>, gaEvent?: IInputGaEvent) => {
		if (isMobile) {
			setUserInputBuffer({ ...(userInputBuffer || {}), ...(partial || {}) });
		} else {
			dispatch(setCalculationInputs(partial));
		}
		gaEvent && ReactGA4.event({ category: 'Input', ...gaEvent });
	};

	const steps: IStepDefinition[] = [
		{
			title: <Tr.Proposal path="calculation-inputs.selector-vehicle.title" />,
			content: (
				<SelectorVehicle
					brand={userInputBuffer?.brand}
					model={userInputBuffer?.model}
					version={userInputBuffer?.version}
					onValueChange={(val) =>
						partialUpdate(
							val,
							val
								? {
										action: 'Vehicle Selection',
										label: `Brand_${val.brand || ''};Model_${val.model || ''};Version_${val.version || ''}`
								  }
								: undefined
						)
					}
					onReset={() => {
						setUserInputBuffer(undefined);
						dispatch(reset());
					}}
				/>
			)
		},
		{
			title: <Tr.Proposal path="calculation-inputs.selector-kilometer.title" />,
			content: (
				<SelectorKilometer
					value={userInputBuffer?.yearlyKM}
					onValueChange={(val) =>
						partialUpdate(
							{ yearlyKM: val },
							{
								action: 'Kilometer Selection',
								label: `km_${val}`
							}
						)
					}
				/>
			)
		},
		{
			title: <Tr.Proposal path="calculation-inputs.selector-driving-habit.title" />,
			content: (
				<SelectorDrivingHabit
					value={userInputBuffer?.habit}
					onValueChange={(val) =>
						partialUpdate(
							{ habit: val },
							{
								action: 'Driving Habit Selection',
								label: `habit_${val}`
							}
						)
					}
				/>
			)
		},
		{
			title: <Tr.Proposal path="calculation-inputs.selector-location.title" />,
			content: (
				<SelectorLocation
					value={userInputBuffer?.place}
					onValueChange={(val) =>
						partialUpdate(
							{ place: val },
							val
								? {
										action: 'Location Selection',
										label: `Location_${val.title || ''}`
								  }
								: undefined
						)
					}
					wallbox={hasWallbox}
					onWallboxChange={(val) => {
						dispatch(setHasWallbox(val));
						ReactGA4.event({ category: 'Input', action: 'Charge at Home Selection', label: val ? 'yes' : 'no' });
					}}
				/>
			)
		}
	];

	useEffect(() => {
		setUserInputBuffer(calculationInputs ? { ...calculationInputs } : {});
	}, [calculationInputs]);

	useEffect(() => {
		const { brand, model, version, yearlyKM, habit, place } = calculationInputs || {};
		const isVehicleStepCompleted = brand && model && version;

		if (!isVehicleStepCompleted) {
			setActiveStep(0);
		} else {
			if (!yearlyKM) {
				setActiveStep(1);
			} else if (!habit) {
				setActiveStep(2);
			} else if (!place) {
				setActiveStep(3);
			} else {
				setActiveStep(0);
			}
		}
	}, [calculationInputs]);

	return (
		<Stack sx={{ height: 1.0 }}>
			<LayoutNavbar sx={{ display: { xs: 'none', [CUSTOM_THEME.mobileUntil]: 'flex' } }} />

			<Stack
				direction="column"
				sx={{
					justifyContent: 'space-between',
					height: 1.0,
					paddingTop: 3,
					paddingBottom: { xs: 5, [CUSTOM_THEME.mobileUntil]: 3 },
					paddingX: { xs: 2.5, [CUSTOM_THEME.mobileUntil]: 4 }
				}}
			>
				<Typography
					variant={isMobile ? 'overline' : 'h4'}
					sx={{ display: { [CUSTOM_THEME.mobileUntil]: 'none' }, marginBottom: { xs: 1.25, [CUSTOM_THEME.mobileUntil]: 3 } }}
				>
					<Tr.Proposal path="calculation-inputs.title" />
				</Typography>

				<Stack sx={{ flexGrow: 1, width: 1.0, justifyContent: 'space-between' }}>
					<Stepper
						activeStep={activeStep}
						orientation={isMobile ? 'horizontal' : 'vertical'}
						connector={isMobile ? <StepConnector sx={{ ...MobileStepperConnectorSx }} /> : null}
						sx={isMobile ? MobileStepperSx : DesktopStepperSx}
					>
						{steps.map((step, index) => (
							<Step key={index} disabled={activeStep === index ? false : activeStep > index ? false : true}>
								<StepLabel StepIconComponent={StepIcon}>{isMobile ? '' : step.title}</StepLabel>
								{!isMobile && <StepContent TransitionProps={{ in: true }}>{step.content}</StepContent>}
							</Step>
						))}
					</Stepper>

					{isMobile && (
						<Stack spacing={5} sx={{ flexGrow: 1, paddingTop: 7.5, paddingBottom: 5, justifyContent: 'space-between' }}>
							<Typography fontSize="28px">{steps[activeStep]?.title}</Typography>
							<Box>{steps[activeStep]?.content}</Box>
							<Button
								variant="contained"
								color="secondary"
								onClick={onNext}
								sx={{ width: 'fit-content', alignSelf: 'center' }}
							>
								<Tr.Proposal path="next" /> →
							</Button>
						</Stack>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
};
