import { StringMap, TOptions } from 'i18next';
import { FC } from 'react';
import { Trans, Translation, TransProps, useTranslation } from 'react-i18next';

interface ITrProps extends TransProps<string> {
	path: string;
	options?: string | TOptions<StringMap>;
	withInsecureTemplating?: boolean;
}

export enum Namespaces {
	Common = 'Common',
	Proposal = 'Proposal'
}

export const Tr: Record<Namespaces, FC<ITrProps>> = {
	[Namespaces.Common]: ({ path, options, children, withInsecureTemplating }) => {
		const ns = 'common';
		const { t } = useTranslation(ns);
		return withInsecureTemplating ? (
			<Trans ns={ns} i18nKey={path} t={t} tOptions={options}>
				{children}
			</Trans>
		) : (
			<Translation ns={ns}>{(tFunc) => tFunc(path, options)}</Translation>
		);
	},
	[Namespaces.Proposal]: ({ path, options, children, withInsecureTemplating }) => {
		const ns = 'proposal';
		const { t } = useTranslation(ns);
		return withInsecureTemplating ? (
			<Trans ns={ns} i18nKey={path} t={t} tOptions={options}>
				{children}
			</Trans>
		) : (
			<Translation ns={ns}>{(tFunc) => tFunc(path, options)}</Translation>
		);
	}
};
