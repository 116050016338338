import { FC, PropsWithChildren } from 'react';
import { instanceToPlain } from 'class-transformer';
import { Tr } from '@utils';
import { HeremapsPlaceSearchAutocompleteItem } from '@services';
import { Stack, Typography } from '@mui/material';
import { LocationSearch, VoltaageSwitchButton } from '@components/common';

export interface ISelectorLocationProps {
	value?: HeremapsPlaceSearchAutocompleteItem;
	onValueChange?: (val?: HeremapsPlaceSearchAutocompleteItem) => void;
	wallbox?: boolean;
	onWallboxChange?: (val?: boolean) => void;
}

export const SelectorLocation: FC<PropsWithChildren<ISelectorLocationProps>> = ({ value, onValueChange, wallbox, onWallboxChange }) => {
	return (
		<Stack sx={{ gap: 2 }}>
			<Typography lineHeight="14.63px" variant="caption">
				<Tr.Proposal path="calculation-inputs.selector-location.subtitle" />
			</Typography>
			<LocationSearch
				value={value}
				onChange={(val) => onValueChange?.(instanceToPlain(val) as HeremapsPlaceSearchAutocompleteItem)}
				inputProps={{ variant: 'outlined', size: 'small' }}
			/>

			<Stack
				direction="row"
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: 4
				}}
			>
				<Typography variant="body2">
					<Tr.Proposal path="calculation-inputs.wallbox-installation" />
				</Typography>
				<VoltaageSwitchButton value={wallbox} onChange={onWallboxChange} />
			</Stack>
		</Stack>
	);
};
