import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { filter } from 'rxjs';
import ReactGA4 from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { proposalsSelector, reset, setMoreInfoRequested } from '@store/slices/proposal-slice';
import { CUSTOM_THEME, RxUtils, Tr } from '@utils';
import { useMobile, useService } from '@hooks';
import {
	B2CCalculationResult,
	B2CCalculationVehicleType,
	b2cFindMoreService,
	B2CService,
	HeremapsPlace,
	HeremapsPlaceSearchChargingPoint,
	notificationService,
	NotificationType
} from '@services';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from '@components/icons';
import { CardVehicleDetail } from '../CardVehicleDetail';
import { CardChargingStations } from '../CardChargingStations';
import { CardSavings } from '../CardSavings';
import { CardCO2Reduction } from '../CardCO2Reduction';
import { CardServices } from '../CardServices';
import { CardFinalPrice } from '../CardFinalPrice';
import { CardVehicleSummary } from '../CardVehicleSummary';
import { CardVehicleTypeSelection } from '../CardVehicleTypeSelection';
import { ModalFindMoreRequestAnimation } from '../ModalFindMoreRequestAnimation';

interface ILayoutResultProps {
	calculation?: B2CCalculationResult;
	services: B2CService[];
	placeDetail?: HeremapsPlace;
	chargingStations: HeremapsPlaceSearchChargingPoint[];
}

export const LayoutResult: FC<PropsWithChildren<ILayoutResultProps>> = ({ calculation, services, placeDetail, chargingStations }) => {
	const dispatch = useDispatch();
	const isMobile = useMobile();
	const [findMoreRequestAnimationVisible, setFindMoreRequestAnimationVisible] = useState(false);
	const [tabValue, setTabValue] = useState<B2CCalculationVehicleType>(B2CCalculationVehicleType.EV);
	const calculationInputs = useSelector(proposalsSelector('calculationInputs'));
	const engagement = useSelector(proposalsSelector('engagement'));
	const selectedServices = useSelector(proposalsSelector('selectedServices'));
	const hasWallbox = useSelector(proposalsSelector('hasWallbox'));
	const hasSolarPanel = useSelector(proposalsSelector('hasSolarPanel'));
	const moreInfoRequested = useSelector(proposalsSelector('moreInfoRequested'));
	const { data: notification } = useService(() =>
		notificationService.getSubject().pipe(filter((notif) => (notif ? notif?.type === NotificationType.ERROR : true)))
	);

	const { brand, model, version, habit } = calculationInputs || {};
	const vehicle = calculation?.[tabValue];
	const onFindMoreClicked = () => {
		if (engagement) {
			ReactGA4.event({ category: 'Calculation', action: 'Find More Clicked' });
			setFindMoreRequestAnimationVisible(true);
			RxUtils.promisify(
				b2cFindMoreService.findMore({
					engagementId: engagement.id,
					services: selectedServices || [],
					interestedBrand: vehicle?.proposed?.b2cBrand || vehicle?.proposed?.carModel?.brand || '',
					interestedModel: vehicle?.proposed?.b2cModel || vehicle?.proposed?.carModel?.model || '',
					interestedVersion: vehicle?.proposed?.b2cVersion || vehicle?.proposed?.carModel?.version || ''
				}),
				() => {
					dispatch(setMoreInfoRequested(true));
					setTimeout(() => setFindMoreRequestAnimationVisible(false), 5000);
				},
				() => {
					() => setFindMoreRequestAnimationVisible(false);
				}
			);
		}
	};
	const getMonthlySaving = () => {
		let proposedVehicleMonthlyCost = vehicle?.cum;
		if (hasSolarPanel) {
			proposedVehicleMonthlyCost = vehicle?.cumWithChargeAtHomeWithSolar;
		} else if (hasWallbox) {
			proposedVehicleMonthlyCost = vehicle?.cumWithChargeAtHome;
		}

		return (calculation?.ice?.cum || 0) - (proposedVehicleMonthlyCost || 0);
	};
	const getYearlySaving = () => getMonthlySaving() * 12;
	const getMonthlyPrice = () => {
		let proposedVehicleMonthlyCost = vehicle?.price;
		if (hasSolarPanel) {
			proposedVehicleMonthlyCost = vehicle?.priceWithChargeAtHomewithSolar;
		} else if (hasWallbox) {
			proposedVehicleMonthlyCost = vehicle?.priceWithChargeAtHome;
		}

		return proposedVehicleMonthlyCost;
	};
	const getElectricityFuelPrice = () => {
		if (hasSolarPanel) {
			return vehicle?.fuelPriceElectricityAtHomeWithSolar;
		} else if (hasWallbox) {
			return vehicle?.fuelPriceElectricityAtHome;
		} else {
			return vehicle?.fuelPriceElectricity;
		}
	};

	useEffect(() => {
		calculation && notificationService.clear();
	}, [calculation]);

	return calculation ? (
		<Stack sx={{ flexGrow: 1, maxHeight: 1.0, minHeight: 1.0, overflow: 'hidden', paddingY: 2 }}>
			{isMobile && (
				<CardVehicleSummary
					brand={brand}
					model={model}
					version={version}
					onReset={() => dispatch(reset())}
					sx={{ marginBottom: 1.5 }}
				/>
			)}
			<Grid
				container
				className="no-scroll"
				sx={{
					flexGrow: 1,
					overflowX: 'hidden',
					overflowY: { xs: 'auto', [CUSTOM_THEME.mobileUntil]: 'hidden' }
				}}
				columnSpacing={{ xs: 0, [CUSTOM_THEME.mobileUntil]: 2.5 }}
			>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						flexGrow: { xs: 0, [CUSTOM_THEME.mobileUntil]: 1 },
						minHeight: { xs: 'auto', [CUSTOM_THEME.mobileUntil]: 1.0 },
						maxHeight: 'calc(100vh - 32px)'
					}}
				>
					<Stack direction="column" spacing={2} sx={{ height: 1.0 }}>
						{!isMobile && (
							<Typography variant="h4" sx={{ display: { xs: 'none', [CUSTOM_THEME.mobileUntil]: 'block', fontSize: 22 } }}>
								<Tr.Proposal path="result.title" />
							</Typography>
						)}
						{!isMobile && <CardVehicleTypeSelection value={tabValue} onChange={setTabValue} calculation={calculation} />}

						<CardVehicleDetail stock={vehicle?.proposed} images={vehicle?.images} habit={habit} />
						<CardChargingStations chargingStations={chargingStations} placeDetail={placeDetail} />
					</Stack>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						flexGrow: { xs: 0, [CUSTOM_THEME.mobileUntil]: 1 },
						minHeight: { xs: 'auto', [CUSTOM_THEME.mobileUntil]: 1.0 },
						maxHeight: { xs: undefined, [CUSTOM_THEME.mobileUntil]: 'calc(100vh - 32px)' },
						paddingTop: { xs: 2.5, [CUSTOM_THEME.mobileUntil]: 0 }
					}}
				>
					<Stack direction="column" spacing={2} sx={{ height: 1.0, maxHeight: 1.0 }}>
						<Grid container spacing={2}>
							<Grid item xs={6} md={12}>
								<CardSavings
									saving={getYearlySaving()}
									electricityPrice={getElectricityFuelPrice()}
									dieselPrice={vehicle?.fuelPriceDiesel}
									gasolinePrice={vehicle?.fuelPriceGasoline}
									sx={{ height: { xs: 1.0, [CUSTOM_THEME.mobileUntil]: undefined } }}
								/>
							</Grid>
							<Grid item xs={6} md={12}>
								<CardCO2Reduction
									treesSavedPerYear={vehicle?.treesSaved}
									co2Total={vehicle?.co2}
									sx={{ height: { xs: 1.0, [CUSTOM_THEME.mobileUntil]: undefined } }}
								/>
							</Grid>
						</Grid>
						<CardServices services={services} />
						<CardFinalPrice
							price={getMonthlyPrice()}
							ctaButtonProps={{ onClick: onFindMoreClicked, disabled: moreInfoRequested }}
						/>
					</Stack>
				</Grid>
			</Grid>
			<ModalFindMoreRequestAnimation open={findMoreRequestAnimationVisible} />
			{isMobile && (
				<Stack direction="column" spacing={3} sx={{ marginTop: 2 }}>
					<CardVehicleTypeSelection value={tabValue} onChange={setTabValue} calculation={calculation} />
				</Stack>
			)}
		</Stack>
	) : (
		<Stack sx={{ height: 1.0, alignItems: 'center', justifyContent: 'center' }}>
			<Stack
				spacing={2.5}
				sx={{ alignItems: 'center', justifyContent: 'center', width: 0.33, color: 'common.white', textAlign: 'center' }}
			>
				<Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 500 }}>
					👋 <Tr.Proposal path="hello" />
				</Typography>
				<Typography variant="body1">
					<Tr.Proposal path="result.no-result" />
				</Typography>
				<IconButton
					onClick={() => {
						dispatch(reset());
						notificationService.clear();
					}}
					sx={{ width: 64, height: 64 }}
				>
					<ArrowLeftIcon />
				</IconButton>
				<Typography variant="body1" sx={{ color: 'error.main' }}>
					{notification ? <Tr.Proposal path="result.error-notification" /> : ''}
				</Typography>
			</Stack>
		</Stack>
	);
};
