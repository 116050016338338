import { FC, PropsWithChildren } from 'react';
import { Tr } from '@utils';
import { B2CCalculationResult, B2CCalculationVehicleType } from '@services';
import { Tab, TabProps, Tabs, TabsProps } from '@mui/material';

const VehicleTypeTabMapping: Record<B2CCalculationVehicleType, TabProps> = {
	[B2CCalculationVehicleType.EV]: { label: <Tr.Proposal path="result.vehicle-type.ev" /> },
	[B2CCalculationVehicleType.PHEV]: { label: <Tr.Proposal path="result.vehicle-type.phev" /> }
};

export interface ICardVehicleTypeSelectionProps extends Omit<TabsProps, 'onChange'> {
	calculation?: B2CCalculationResult;
	value: B2CCalculationVehicleType;
	onChange?: (val: B2CCalculationVehicleType) => void;
}

export const CardVehicleTypeSelection: FC<PropsWithChildren<ICardVehicleTypeSelectionProps>> = ({
	onChange,
	calculation,
	sx,
	...props
}) => {
	return (
		<Tabs sx={{ outline: 'none', ...sx }} {...props} onChange={(_, tab) => onChange?.(tab)}>
			{[B2CCalculationVehicleType.EV, B2CCalculationVehicleType.PHEV].map((type) => (
				<Tab key={type} value={type} disabled={calculation && !calculation[type]} {...VehicleTypeTabMapping[type]} />
			))}
		</Tabs>
	);
};
